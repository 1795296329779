import { createSlice } from '@reduxjs/toolkit'

const contractExpiryPopupSlice = createSlice({
  name: 'contractExpiryPopup',
  initialState: {
    snoozeUntil: null,
  },
  reducers: {
    setModalSnooze: (state, action) => {
      state.snoozeUntil = action.payload
    },
    clearModalSnooze: (state) => {
      state.snoozeUntil = null
    },
  },
})

export const { setModalSnooze, clearModalSnooze } =
  contractExpiryPopupSlice.actions
export const contractExpiryPopup = contractExpiryPopupSlice.reducer
