import { Button } from '@material-ui/core'
import { ThemeProvider, createTheme } from '@mui/material'
import React from 'react'

const SecondaryButton = ({
  onClick,
  title,
  minWidth,
  bgcolor = `rgba(40, 32, 61, 0.07)`,
  color = 'black',
}) => {
  const buttonTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: `none`,
          },
        },
      },
    },
  })
  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        sx={{
          bgcolor: bgcolor,
          color: color,
          padding: `10px`,
          width: { xs: `100%`, sm: `auto` },
          minWidth: minWidth,
          display: `flex`,
          justifyContent: `center`,
          alignItems: `center`,
          borderRadius: `10px`,
          '&:hover': { cursor: `pointer`, bgcolor: bgcolor, opacity: '0.8' },
        }}
        onClick={onClick}
      >
        {title}
      </Button>
    </ThemeProvider>
  )
}

export default SecondaryButton
