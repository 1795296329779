import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fixedAssetType: '',
  fixedAssetEntityName: '',
  fixedAsset: {},
}

const fixedAssetSlice = createSlice({
  name: 'fixedAsset',
  initialState,
  reducers: {
    setfixedAssetType: (state, { payload }) => {
      state.fixedAssetType = payload
    },
    setfixedAssetEntityName: (state, { payload }) => {
      state.fixedAssetEntityName = payload
    },
    setfixedAsset: (state, { payload }) => {
      state.fixedAsset = payload
    },
  },
})

export const getfixedAssetType = (state) => state.fixedAsset.fixedAssetType
export const getfixedAssetEntityName = (state) =>
  state.fixedAsset.fixedAssetEntityName
export const getfixedAsset = (state) => state.fixedAsset.fixedAsset

export const { setfixedAssetType, setfixedAssetEntityName, setfixedAsset } =
  fixedAssetSlice.actions
export const fixedAsset = fixedAssetSlice.reducer
