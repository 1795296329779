import { Box, CircularProgress, Link, Typography } from '@material-ui/core'

import api from 'app/AxiosInstance'
import { baseUrl } from 'app/Utils/Config'
import { pageColor, pageHeight } from 'app/Utils/pageProperties'
import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import CustomDropdown from './CustomDropDown'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import {
  APPLICANT_DETAILS_DROPDOWN_OPTIONS,
  APPLICANT_STATS_DATA,
} from 'app/Utils/data'

const ApplicantDetail = () => {
  const { id } = useParams()
  const [status, setStatus] = useState(``)
  const [initialStatus, setInitialStatus] = useState(``)

  const openSnackbar = useContext(SnackbarContext)
  const queryClient = useQueryClient()
  const fetchData = async () => {
    const data = await api.get(`${baseUrl}/business-applicants/${id}`)
    return data?.data?.data
  }

  const {
    data: applicantDetails,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [`applicantDetails`, id],
    queryFn: fetchData,
    refetchOnMount: true,
  })

  useEffect(() => {
    if (applicantDetails) {
      setStatus(applicantDetails.status)
      setInitialStatus(applicantDetails.status)
    }
  }, [applicantDetails])

  const { mutate: updateStatus, isLoading: isUpdating } = useMutation(
    async (newStatus) => {
      const data = await api.patch(`${baseUrl}/business-applicants/${id}`, {
        status: newStatus,
      })
      return data
    },
    {
      onSuccess: () => {
        openSnackbar(`Status Updated Successfully`, `success`)
        queryClient.removeQueries([`applicantData`])
        refetch()
      },
      onError: () => {
        openSnackbar(`Error updating status`, `error`)
      },
    },
  )

  const handleCancel = () => {
    setStatus(initialStatus)
  }

  const hasStatusChanged = status !== initialStatus

  const getDisplayValue = (key, value) => {
    if (key === `availableToWorkInUSTimeZone`) {
      return value ? `Yes` : `No`
    }
    return value
  }
  return (
    <Box minHeight={pageHeight} p={`2rem`} bgcolor={pageColor}>
      <Box
        display={`flex`}
        flexDirection={`column`}
        width={`100%`}
        minHeight={`75vh`}
        bgcolor={`#ffffff`}
        borderRadius={`10px`}
        p={`1.5rem`}
        pt={`1rem`}
        gap={`1rem`}
      >
        <Box
          display={`flex`}
          flexDirection={{ md: `row`, xs: `column` }}
          justifyContent={`space-between`}
          alignItems={{ xs: `center`, lg: `normal` }}
          gap={{ xs: `1rem`, md: 0 }}
        >
          <Box
            display={`flex`}
            flexDirection={`column`}
            alignItems={{ md: `flex-start`, xs: `center` }}
          >
            <Typography fontSize={`36px`} fontWeight={`600`}>
              {applicantDetails?.firstName} {applicantDetails?.lastName}
            </Typography>
            <Typography variant="heading4" color={`#19C9D1`}>
              <Link
                color={`inherit`}
                underline="false"
                href={applicantDetails?.linkedinProfileLink}
              >
                {applicantDetails?.linkedinProfileLink}
              </Link>
            </Typography>
          </Box>
          <Box
            display={`flex`}
            gap={`1rem`}
            alignItems={`center`}
            height={`center`}
          >
            {hasStatusChanged && (
              <>
                <Typography
                  onClick={handleCancel}
                  variant="body-text"
                  sx={{
                    color: `#FF485E`,
                    ':hover': {
                      cursor: `pointer`,
                    },
                  }}
                >
                  Cancel
                </Typography>
                <Typography
                  variant="body-text"
                  sx={{
                    color: `#6039BB`,
                    ':hover': {
                      cursor: `pointer`,
                    },
                  }}
                  onClick={() => updateStatus(status)}
                >
                  Done
                </Typography>
              </>
            )}
            <CustomDropdown
              label={status}
              selectedValue={status}
              setSelectedValue={setStatus}
              options={APPLICANT_DETAILS_DROPDOWN_OPTIONS}
              disabled={isUpdating}
            />
          </Box>
        </Box>

        {isLoading ? (
          <Box
            display={`flex`}
            justifyContent={`center`}
            alignItems={`center`}
            bgcolor={`rgba(25, 201, 209, 0.1)`}
            p={`1.5rem`}
            borderRadius={`10px`}
            minHeight={`200px`}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            display={`grid`}
            gridTemplateColumns={{
              lg: `1fr 1fr 1fr`,
              md: `1fr 1fr`,
              xs: `1fr`,
            }}
            gap={`1rem`}
            bgcolor={`rgba(25, 201, 209, 0.1)`}
            p={`1.5rem`}
            borderRadius={`10px`}
          >
            {APPLICANT_STATS_DATA?.map((item, index) => {
              return (
                <Box
                  key={index}
                  display={`flex`}
                  flexDirection={`column`}
                  alignItems={`start`}
                >
                  <Typography variant="bodyText">{item.title}</Typography>
                  {item.key === `resume` ? (
                    <Typography
                      variant="heading4"
                      color={`#19C9D1`}
                      sx={{
                        ':hover': {
                          cursor: `pointer`,
                        },
                      }}
                    >
                      <Link href={applicantDetails[item.key]}>
                        View / Download
                      </Link>
                    </Typography>
                  ) : (
                    <Typography variant="heading4">
                      {getDisplayValue(item.key, applicantDetails[item.key])}
                    </Typography>
                  )}
                </Box>
              )
            })}
          </Box>
        )}

        <Box
          display={`flex`}
          flexDirection={`column`}
          pl={`0.8rem`}
          gap={`1rem`}
        >
          <Typography variant="heading3">Questions</Typography>

          {isLoading ? (
            <Box
              display={`flex`}
              justifyContent={`center`}
              alignItems={`center`}
              p={`1.5rem`}
              borderRadius={`10px`}
              minHeight={`200px`}
            >
              <CircularProgress />
            </Box>
          ) : (
            applicantDetails?.questions?.map((ques, index) => {
              return (
                <Box key={ques.ques} display={`flex`} flexDirection={`column`}>
                  <Typography
                    variant="bodyText"
                    fontWeight={`600`}
                    color={`#28203D`}
                  >
                    {index + 1}. {ques.question}
                  </Typography>
                  <Typography variant="bodyText" color={`#28203D`}>
                    {ques.answer}
                  </Typography>
                </Box>
              )
            })
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ApplicantDetail
