import React, { useState, useContext, useRef, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Divider,
  Typography,
  TextField,
  Button,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core'
import cross from 'app/Assets/cross.svg'
import api from 'app/AxiosInstance'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { useQuery, useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from 'moment/moment'
import file from 'app/Assets/file_ic.svg'
import ReactInputMask from 'react-input-mask'
import PrependRs from 'app/Utils/PrependRs'
import { baseUrl } from 'app/Utils/Config'
import { getAllAccounts } from 'app/Services/General/getAllAccounts'
import Image from 'mui-image'
import uploadIcon from '../../../Assets/uploadDocument_ic.svg'
import { getAllBusiness } from 'app/Services/General/getAllBusiness'
import { getConfiguration } from 'app/Services/General/getConfiguration'
import { getRole } from './../../../Utils/helper'

const AddExpense = ({ open, onClose, fetchExpenses, accountName }) => {
  const colors = useSelector((state) => state.colors)
  const openSnackbar = useContext(SnackbarContext)
  const token = localStorage.getItem(`AuthToken`)
  const loggedInUserId = localStorage.getItem(`loggedInUserId`)
  const systemRole = getRole()

  const [accountsData, setAccountsData] = useState([])
  const [businessData, setBusinessData] = useState([])

  const [pkrAmount, setpkrAmount] = useState(0)
  const [accountBalance, setAccountBalance] = useState([])
  const [configRate, setConfigRate] = useState(1)

  const { data: userAddress } = useQuery(
    `getSingleUserApi`,
    async () =>
      await api
        .get(`${baseUrl}/users/${loggedInUserId}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res.data.data?.address?.country
        }),

    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  useEffect(() => {
    getAllAccounts().then((result) => {
      const newLoadAccounts = result?.options
        ?.map((account) => {
          if (
            account?.name &&
            !account.name.toLowerCase().includes(`payroll`)
          ) {
            return {
              label: account?.name,
              value: account?._id,
            }
          }
          return null // Filter out accounts with "payroll" in their name
        })
        .filter(Boolean) // Remove null entries from the array
      setAccountsData(newLoadAccounts)
    })
    getAllBusiness().then((result) => {
      const newLoadBusiness = result?.options?.map((business) => {
        return {
          label: business?.name,
          value: business?._id,
          country: business?.country,
        }
      })

      setBusinessData(newLoadBusiness)
    })
    getConfiguration().then((result) => {
      setConfigRate(result)
    })
  }, [])

  const [confirmationBox, setConfirmationBox] = useState(false)
  const hiddenFileInput = useRef(null)
  const formData = new FormData()
  const [mediaName, setMediaName] = useState([])
  const [warningType, setWarningType] = useState(``)
  const [budgetDuration, setBudgetDuration] = useState([])

  const [expense, setExpense] = useState({
    account: accountName ? accountName.id : ``,
    name: ``,
    business: ``,
    budgetDuration: ``,
    originalAmount: ``,
    conversionRate: 1,
    usdConversionRate: 1,
    currency: `PKR`,
    date: moment(new Date()).format(`YYYY-MM-DD`),
    expenseMonth: moment(new Date()).format(`MMM-YYYY`),
    description: ``,
    receipt: [],
  })

  useEffect(() => {
    setExpense((prevState) => ({
      ...prevState,
      conversionRate:
        expense?.currency === `PKR`
          ? 1
          : expense?.currency === `USD`
          ? configRate?.pkrConversionRate
          : null,
      usdConversionRate: configRate ? configRate?.pkrConversionRate : 1,
    }))
  }, [configRate, expense?.currency])

  useEffect(() => {
    const businessAddress =
      businessData &&
      businessData?.filter((data) => data?.country === userAddress)
    if (systemRole === `HRM`) {
      setExpense((prevState) => ({
        ...prevState,
        business: businessAddress[0],
      }))
    }
  }, [businessData, userAddress])

  const [formErrors, setFormErrors] = useState({
    formSubmit: false,
    account: false,
    business: false,
    name: false,
    originalAmount: false,
    conversionRate: false,
    usdConversionRate: false,
    currency: false,
    date: false,
    description: false,
    budgetDuration: false,
    expenseMonth: false,
  })

  const { data: commonCurrency } = useQuery(
    `getCurrencies`,
    async () =>
      await api
        .get(`https://openexchangerates.org/api/currencies.json`)
        .then((res) => {
          if (!res) undefined

          const currencies = Object.entries(res.data).map(([code, name]) => ({
            code,
            name,
          }))

          return currencies
        }),

    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )

  useEffect(() => {
    if (expense.originalAmount && expense.conversionRate) {
      const convertedAmount = expense.originalAmount * expense.conversionRate
      setpkrAmount(convertedAmount)
    } else {
      setpkrAmount(0)
    }
  }, [expense.conversionRate, expense.originalAmount])

  const { data: fund, isLoading: fundLoader } = useQuery(
    `getFundsDataApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/funds?allocatedBy=&allocatedTo=&startDate=&endDate=&below=true&utilized=true&exceeded=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          const filteredFunds =
            res &&
            res?.data?.data?.filter(
              (fund) => fund?.allocatedTo?._id === loggedInUserId,
            )
          return filteredFunds
        }),

    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const { mutate: addExpense, isLoading } = useMutation(
    async (payload) =>
      await api
        .post(`${baseUrl}/expenses`, {
          ...payload,
        })
        .then((res) => {
          if (res?.data) {
            onClose()
            openSnackbar(`Expense added successfully`, `success`)
            res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        fetchExpenses()
      },
      onError: (error) => {
        openSnackbar(
          error?.response
            ? error?.response?.data?.message
            : error?.message
            ? error?.message
            : `Something went wrong`,
          `error`,
        )
      },
    },
  )
  useEffect(async () => {
    try {
      const response = await getAllAccounts()
      setBudgetDuration(() =>
        response?.options?.find((data) => data?._id === expense?.account),
      )
    } catch (error) {
      openSnackbar(`Error fetching accounts:`, 'error')
    }
  }, [expense?.account])

  const { mutate: getFileUrl, isLoading: fileUploading } = useMutation(
    async () => {
      try {
        const res = await api.post(
          `${baseUrl}/users/upload-multiple-documents`,
          formData,
          {
            headers: {
              'Content-Type': `multipart/form-data`,
            },
          },
        )

        res?.data?.data?.map((attachment) => {
          setExpense((prevExpense) => ({
            ...prevExpense,
            receipt: [
              ...prevExpense.receipt,
              {
                url: attachment?.attachment,
                key: attachment?.key,
              },
            ],
          }))
          setMediaName((prevMediaNames) => [
            ...prevMediaNames,
            {
              fileName: attachment?.fileName,
              uniqueId: attachment?.attachment,
            },
          ])
        })
      } catch (error) {
        openSnackbar(`Uploading failed`, `error`)
      }
    },
  )

  const handleFileUpload = (files) => {
    formData.append(`documents`, files[0])
    getFileUrl()
    hiddenFileInput.current.value = null
  }

  const handleCrossPhoto = (itemToRemove) => {
    const filteredReceipt = expense?.receipt?.filter(
      (item) => item?.url !== itemToRemove?.uniqueId,
    )

    setExpense((prevExpense) => ({
      ...prevExpense,
      receipt: filteredReceipt,
    }))

    setMediaName((prevMediaNames) =>
      prevMediaNames.filter((item) => item.uniqueId !== itemToRemove?.uniqueId),
    )
  }
  const validateForm = () => {
    if (!expense?.account?.length) {
      setFormErrors((prevState) => ({
        ...prevState,
        account: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        account: false,
      }))
    }
    if (!expense?.business) {
      setFormErrors((prevState) => ({
        ...prevState,
        business: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        business: false,
      }))
    }
    if (!expense?.budgetDuration?.length) {
      setFormErrors((prevState) => ({
        ...prevState,
        budgetDuration: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        budgetDuration: false,
      }))
    }

    if (!expense?.originalAmount) {
      setFormErrors((prevState) => ({
        ...prevState,
        originalAmount: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        originalAmount: false,
      }))
    }

    if (!expense?.conversionRate) {
      setFormErrors((prevState) => ({
        ...prevState,
        conversionRate: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        conversionRate: false,
      }))
    }
    if (!expense?.usdConversionRate) {
      setFormErrors((prevState) => ({
        ...prevState,
        usdConversionRate: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        usdConversionRate: false,
      }))
    }

    if (!expense?.currency) {
      setFormErrors((prevState) => ({
        ...prevState,
        currency: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        currency: false,
      }))
    }

    if (!expense?.name?.length) {
      setFormErrors((prevState) => ({
        ...prevState,
        name: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        name: false,
      }))
    }
    if (!expense?.date) {
      setFormErrors((prevState) => ({
        ...prevState,
        date: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        date: false,
      }))
    }
    if (!expense?.expenseMonth) {
      setFormErrors((prevState) => ({
        ...prevState,
        expenseMonth: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        expenseMonth: false,
      }))
    }
    if (!expense?.description?.length) {
      setFormErrors((prevState) => ({
        ...prevState,
        description: true,
      }))
    } else {
      setFormErrors((prevState) => ({
        ...prevState,
        description: false,
      }))
    }

    setFormErrors((prevState) => ({
      ...prevState,
      formSubmit: true,
    }))
  }

  useEffect(() => {
    if (
      formErrors?.formSubmit &&
      !formErrors?.business &&
      !formErrors?.usdConversionRate &&
      !formErrors?.originalAmount &&
      !formErrors?.conversionRate &&
      !formErrors?.currency &&
      !formErrors?.date &&
      !formErrors?.description &&
      !formErrors?.account &&
      !formErrors?.budgetDuration &&
      !formErrors?.expenseMonth &&
      !formErrors?.name
    ) {
      if (
        fund[0]?.utilized > fund[0]?.total &&
        accountBalance &&
        accountBalance[0]?.utilized > accountBalance[0]?.total
      ) {
        setWarningType(`fund and budget`)
        setConfirmationBox(!confirmationBox)
      } else if (fund[0]?.utilized > fund[0]?.total) {
        setWarningType(`fund`)
        setConfirmationBox(!confirmationBox)
      } else if (
        accountBalance &&
        accountBalance[0]?.utilized > accountBalance[0]?.total
      ) {
        setWarningType(`budget`)
        setConfirmationBox(!confirmationBox)
      } else {
        addExpense(handleAddExpense())
      }

      setFormErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    } else if (formErrors?.formSubmit) {
      openSnackbar(`Please fill out all required fields`, `error`)
    }
  }, [formErrors])

  const handleAddExpense = () => {
    const data = {}

    if (expense?.account?.length) {
      data.account = expense?.account
    }
    if (systemRole === `HRM`) {
      if (expense?.business) {
        data.business = expense?.business?.value
      }
    } else {
      if (expense?.business?.length) {
        data.business = expense?.business
      }
    }

    if (expense?.originalAmount) {
      data.originalAmount = parseInt(expense?.originalAmount)
    }

    if (expense?.conversionRate) {
      data.conversionRate = parseFloat(expense?.conversionRate)
    }
    if (expense?.usdConversionRate) {
      data.usdConversionRate = parseFloat(expense?.usdConversionRate)
    }

    if (expense?.currency) {
      data.currency = expense?.currency
    }

    if (expense?.name) {
      data.name = expense?.name
    }
    if (expense?.date) {
      data.date = moment(expense?.date).format(`YYYY-MM-DD`).toString()
    }
    if (expense?.expenseMonth) {
      data.expenseMonth = moment(expense?.expenseMonth)
        .format(`YYYY-MM`)
        .toString()
    }

    if (expense?.description?.length) {
      data.description = expense?.description
    }
    if (expense?.budgetDuration?.length) {
      data.accountsBudget = expense?.budgetDuration
    }
    if (expense?.receipt?.length) {
      data.receipts = expense?.receipt
    }

    return data
  }
  const [hasFund, setHasFund] = useState(false)
  useEffect(() => {
    {
      if (!fundLoader && (fund?.length === 0 || !fund)) setHasFund(!hasFund)
    }
  }, [fund, fundLoader])

  useEffect(() => {
    setAccountBalance(() =>
      budgetDuration?.budget?.filter(
        (data) => data?._id === expense?.budgetDuration,
      ),
    )
  }, [expense?.budgetDuration])

  return (
    <>
      {hasFund && (
        <Dialog open={hasFund}>
          <DialogTitle sx={{ color: `#FF485E`, fontWeight: 600 }}>
            Not Allowed!!
          </DialogTitle>
          <DialogContent>
            No funds have been assigned to you, thus you are unable to add any
            expenses
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onClose}>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={open}
        onClose={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: `876px`,
            maxWidth: `lg`,
            borderRadius: `20px`,
          },
        }}
      >
        {confirmationBox && (
          <Dialog open={confirmationBox}>
            <DialogTitle sx={{ color: `#FF485E`, fontWeight: 600 }}>
              Warning!!
            </DialogTitle>
            <DialogContent>
              Expense exceeds allocated {warningType}. Are you sure you still
              want to proceed?
            </DialogContent>
            <DialogActions>
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  gap: `10px`,
                  marginBottom: `10px`,
                }}
              >
                <Box
                  sx={{
                    bgcolor: `rgba(40, 32, 61, 0.07)`,
                    color: `black`,
                    width: `85px`,
                    height: `36px`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    '&:hover': { cursor: `pointer`, opacity: 0.8 },
                  }}
                  onClick={() => setConfirmationBox(false)}
                >
                  No
                </Box>
                <Box
                  sx={{
                    bgcolor: `#FF485E`,
                    color: `white`,
                    width: `85px`,
                    height: `36px`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    '&:hover': { cursor: `pointer`, opacity: 0.8 },
                  }}
                  onClick={() => {
                    addExpense(handleAddExpense())
                    setConfirmationBox(!confirmationBox)
                  }}
                >
                  Yes
                </Box>
              </Box>
            </DialogActions>
          </Dialog>
        )}
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            height: `48px`,
            display: `flex`,
            flexDirection: `row-reverse`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <Box
            sx={{
              width: `36px`,
              height: `36px`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              padding: `11px`,
              '&:hover': {
                cursor: `pointer`,
                opacity: 0.8,
              },
            }}
            onClick={onClose}
          >
            <img src={cross} alt="cross" width="13.87px" height="14px" />
          </Box>
          <Typography
            sx={{
              fontFamily: `Inter`,
              fontSize: {
                lg: 16,
                md: 13,
                sm: 10,
                xs: 8,
              },
              fontWeight: 600,
            }}
          >
            Add Expense
          </Typography>
        </DialogTitle>
        <Divider sx={{ width: `100%` }} />

        <DialogContent
          sx={{
            display: `flex`,
            flexDirection: `column`,
            gap: `10px`,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} container spacing={2}>
              {businessData && systemRole === `HRM` ? (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-basic"
                      label="Business *"
                      variant="outlined"
                      size="small"
                      aria-readonly
                      focused
                      value={expense.business?.label}
                      InputLabelProps={{
                        style: {
                          color: formErrors.business
                            ? `#FF485E`
                            : expense?.business
                            ? colors.appColor
                            : `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            border: formErrors.business
                              ? `solid #FF485E 1px`
                              : `solid ${colors.appColor} 1px`,
                          },
                          '&.Mui-focused fieldset': {
                            border: formErrors.business
                              ? `solid #FF485E 1px`
                              : `solid ${colors.appColor} 1px`,
                          },
                          '& fieldset': {
                            borderColor: formErrors.business
                              ? `#FF485E`
                              : expense?.business
                              ? colors.appColor
                              : `rgba(40, 32, 61, 0.25)`,
                            borderRadius: `10px`,
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          border: formErrors.business
                            ? `solid #FF485E 1px`
                            : `solid ${colors.appColor} 1px`,
                        },
                        '&.Mui-focused fieldset': {
                          border: formErrors.business
                            ? `solid #FF485E 1px`
                            : `solid ${colors.appColor} 1px`,
                        },
                        '& fieldset': {
                          borderColor: formErrors.business
                            ? `#FF485E`
                            : `rgba(40, 32, 61, 0.25)`,
                          borderRadius: `10px`,
                        },
                      },
                      '& .MuiInputLabel-root': {
                        '&.Mui-focused': {
                          color: formErrors.business
                            ? `#FF485E`
                            : `rgba(40, 32, 61, 0.25)`,
                        },
                      },
                    }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        color: formErrors.business
                          ? `#FF485E`
                          : `rgba(40, 32, 61, 0.5)`,
                      }}
                    >
                      Business *
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Business *"
                      value={expense.business}
                      onChange={(event) =>
                        setExpense((prev) => ({
                          ...prev,
                          business: event.target.value,
                        }))
                      }
                      MenuProps={{
                        PaperProps: { sx: { maxHeight: `450px` } },
                      }}
                    >
                      {businessData &&
                        businessData.map((business) => (
                          <MenuItem key={business.value} value={business.value}>
                            {business.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: `flex`,
                    gap: `10px`,
                    justifyContent: `flex-start`,
                    alignItems: `center`,
                    borderRadius: `10px`,
                    backgroundColor:
                      fund && fund[0]?.utilized > fund[0]?.total
                        ? `rgba(255, 72, 94, 0.12)`
                        : `rgba(252, 233, 216, 1)`,
                    paddingX: `10px`,
                    height: `100%`,
                  }}
                >
                  <Typography
                    sx={{
                      color: `
rgba(40, 32, 61, 0.6)`,
                      fontWeight: 400,
                      fontSize: `18px`,
                      lineHeight: `21.78px`,
                    }}
                  >
                    Funds
                  </Typography>
                  <Typography>
                    {PrependRs({
                      amount: (fund && fund[0]?.remaining.toFixed(2)) || 0,
                      sx: {
                        fontSize: { xs: `18px`, sm: `14px`, md: `20px` },
                        fontWeight: 600,
                        lineHeight: `24.2px`,
                        textAlign: `left`,
                        color:
                          fund &&
                          (fund[0]?.utilized > fund[0]?.total
                            ? `rgba(255, 72, 94, 1)`
                            : `rgba(40, 32, 61, 1)`),
                      },
                    })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ paddingBottom: `10px` }}>
              <Grid xs={12} sm={6} sx={{ paddingRight: `10px` }}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: formErrors.account
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: formErrors.account
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: formErrors.account
                          ? `#FF485E`
                          : expense.account
                          ? colors.appColor
                          : `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: formErrors.account
                          ? `#FF485E`
                          : expense.account
                          ? colors.appColor
                          : `rgba(40, 32, 61, 0.25)`,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: formErrors.account
                        ? `#FF485E`
                        : expense.account
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                    }}
                  >
                    Accounts *
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Accounts *"
                    value={expense.account}
                    onChange={(event) =>
                      setExpense((prev) => ({
                        ...prev,
                        account: event.target.value,
                      }))
                    }
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: `450px` } },
                    }}
                  >
                    {accountsData &&
                      accountsData?.map((account) => (
                        <MenuItem key={account?.value} value={account?.value}>
                          {account?.label}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    marginTop: `15px`,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: formErrors.budgetDuration
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: formErrors.budgetDuration
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: formErrors.budgetDuration
                          ? `#FF485E`
                          : `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: formErrors.budgetDuration
                          ? `#FF485E`
                          : `rgba(40, 32, 61, 0.25)`,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: formErrors.budgetDuration
                        ? `#FF485E`
                        : `rgba(40, 32, 61, 0.5)`,
                    }}
                  >
                    Budget Duration *
                  </InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Budget Duration *"
                    value={expense.budgetDuration}
                    onChange={(event) =>
                      setExpense((prev) => ({
                        ...prev,
                        budgetDuration: event.target.value,
                      }))
                    }
                    disabled={!expense?.account}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: `450px` } },
                    }}
                  >
                    {budgetDuration && budgetDuration?.budget?.length > 0 ? (
                      budgetDuration?.budget?.map((data) => (
                        <MenuItem key={data?._id} value={data?._id}>
                          {`${moment(data?.startDate).format(
                            `YYYY-MM-DD`,
                          )}-${moment(data?.endDate).format(`YYYY-MM-DD`)}`}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Budget found</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={6}>
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    gap: `5px`,
                    height: `100%`,
                    paddingLeft: `10px`,
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `rgba(37, 187, 251, 0.15)`,
                      height: `100%`,
                      width: `100%`,
                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `flex-start`,
                      justifyContent: `center`,
                      gap: `10px`,
                      paddingX: `10px`,
                    }}
                  >
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `left`,
                      }}
                    >
                      Total Budget
                    </Typography>
                    <Typography>
                      {PrependRs({
                        amount:
                          (accountBalance &&
                            accountBalance[0]?.total.toFixed(2)) ||
                          0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `left`,
                        },
                      })}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `
            rgba(96, 57, 187, 0.15)`,
                      height: `100%`,
                      display: `flex`,
                      width: `100%`,

                      flexDirection: `column`,
                      alignItems: `flex-start`,
                      justifyContent: `center`,
                      gap: `10px`,
                      paddingX: `10px`,
                    }}
                  >
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `left`,
                      }}
                    >
                      Utilized Budget
                    </Typography>
                    <Typography>
                      {PrependRs({
                        amount:
                          (accountBalance &&
                            accountBalance[0]?.utilized.toFixed(2)) ||
                          0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `left`,
                        },
                      })}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: `10px`,
                      backgroundColor: `
            rgba(96, 57, 187, 0.15)`,
                      height: `100%`,
                      width: `100%`,

                      display: `flex`,
                      flexDirection: `column`,
                      alignItems: `flex-start`,
                      justifyContent: `center`,
                      gap: `10px`,
                      paddingX: `10px`,
                    }}
                  >
                    <Typography
                      sx={{
                        color: `rgba(40, 32, 61, 0.6)`,
                        fontSize: { xs: `14px`, sm: `12px` },
                        fontWeight: 400,
                        lineHeight: `16.94px`,
                        textAlign: `left`,
                      }}
                    >
                      Remaining Budget
                    </Typography>
                    <Typography>
                      {PrependRs({
                        amount:
                          (accountBalance &&
                            accountBalance[0]?.remaining.toFixed(2)) ||
                          0,
                        sx: {
                          fontSize: { xs: `18px`, sm: `14px`, md: `18px` },
                          fontWeight: 600,
                          lineHeight: `21.78px`,
                          textAlign: `left`,
                          color:
                            accountBalance &&
                            accountBalance[0]?.utilized >
                              accountBalance[0]?.total
                              ? `rgba(255, 72, 94, 1)`
                              : `rgba(40, 32, 61, 1)`,
                        },
                      })}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Expense by *"
                  variant="outlined"
                  size="small"
                  value={expense.name}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      name: event.target.value,
                    }))
                  }
                  InputLabelProps={{
                    style: {
                      color: formErrors.name
                        ? `#FF485E`
                        : expense?.name
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: formErrors.name
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: formErrors.name
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: formErrors.name
                          ? `#FF485E`
                          : expense?.name
                          ? colors.appColor
                          : `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                  <DatePicker
                    inputFormat="YYYY-MM-DD"
                    label="Date *"
                    value={expense?.date || null}
                    onChange={(date) =>
                      setExpense((prev) => ({
                        ...prev,
                        date: moment(new Date(date)).format(`YYYY-MM-DD`),
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              border: formErrors.date
                                ? `solid #FF485E 1px`
                                : `solid ${colors.appColor} 1px`,
                            },
                            '&.Mui-focused fieldset': {
                              border: formErrors.date
                                ? `solid #FF485E 1px`
                                : `solid ${colors.appColor} 1px`,
                            },
                            '& fieldset': {
                              borderColor: formErrors.date
                                ? `#FF485E`
                                : expense?.date
                                ? colors.appColor
                                : `rgba(40, 32, 61, 0.25)`,
                              borderRadius: `10px`,
                            },
                            '& svg': { color: `#19C9D1` },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: formErrors.date
                              ? `#FF485E`
                              : expense?.date
                              ? colors.appColor
                              : `rgba(40, 32, 61, 0.5)`,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                  <DatePicker
                    views={[`month`, `year`]}
                    inputFormat="MMM-YYYY"
                    label="Month *"
                    value={expense?.expenseMonth || null}
                    onChange={(date) =>
                      setExpense((prev) => ({
                        ...prev,
                        expenseMonth: moment(new Date(date)).format(`YYYY-MM`),
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              border: formErrors.expenseMonth
                                ? `solid #FF485E 1px`
                                : `solid ${colors.appColor} 1px`,
                            },
                            '&.Mui-focused fieldset': {
                              border: formErrors.expenseMonth
                                ? `solid #FF485E 1px`
                                : `solid ${colors.appColor} 1px`,
                            },
                            '& fieldset': {
                              borderColor: formErrors.expenseMonth
                                ? `#FF485E`
                                : expense?.expenseMonth
                                ? colors.appColor
                                : `rgba(40, 32, 61, 0.25)`,
                              borderRadius: `10px`,
                            },
                            '& svg': { color: `#19C9D1` },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: formErrors.expenseMonth
                              ? `#FF485E`
                              : expense?.date
                              ? colors.appColor
                              : `rgba(40, 32, 61, 0.5)`,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: formErrors.currency
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: formErrors.currency
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: formErrors.currency
                        ? `#FF485E`
                        : expense?.currency
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: formErrors?.currency ? `#FF485E` : colors.appColor,
                    },
                  },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: formErrors?.currency ? `#FF485E` : colors.appColor,
                  }}
                >
                  Currency *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Currency *"
                  id="demo-simple-select"
                  value={expense.currency}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      currency: event.target.value,
                    }))
                  }
                >
                  {commonCurrency?.map((currency) => {
                    return (
                      <MenuItem key={currency.code} value={currency.code}>
                        {`${currency.code} ${currency.name}`}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: formErrors.originalAmount
                        ? `solid #FF485E 1px`
                        : `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: formErrors.originalAmount
                        ? `solid #FF485E 1px`
                        : `solid  ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: formErrors.originalAmount
                        ? `#FF485E`
                        : expense?.originalAmount
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                }}
              >
                <ReactInputMask
                  mask="9999999999999"
                  maskChar=""
                  value={expense?.originalAmount}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      originalAmount: parseInt(event.target.value),
                    }))
                  }
                >
                  {(inputProps) => (
                    <TextField
                      id="outlined-basic"
                      label="Expense Amount *"
                      name="amount"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{
                        style: {
                          color: formErrors.originalAmount
                            ? `#FF485E`
                            : expense?.originalAmount
                            ? colors.appColor
                            : `rgba(40, 32, 61, 0.5)`,
                        },
                      }}
                      {...inputProps}
                    />
                  )}
                </ReactInputMask>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: `16px`,
                  lineHeight: `19.36px`,
                }}
              >
                Expense Amount (PKR, USD)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: formErrors.conversionRate
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: formErrors.conversionRate
                          ? `solid #FF485E 1px`
                          : `solid  ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: formErrors.conversionRate
                          ? `#FF485E`
                          : expense?.conversionRate
                          ? colors.appColor
                          : `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: `none`,
                      },
                  }}
                  id="outlined-basic"
                  label="PKR Conversion Rate *"
                  name="conversionRate"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={parseFloat(expense?.conversionRate)}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      conversionRate: parseFloat(event.target.value),
                    }))
                  }
                  InputLabelProps={{
                    style: {
                      color: formErrors.conversionRate
                        ? `#FF485E`
                        : expense?.conversionRate
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid  ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: `none`,
                      },
                  }}
                  aria-readonly
                  id="outlined-basic"
                  label="PKR Amount *"
                  name="pkrAmount"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={pkrAmount.toFixed(2) || 0}
                  InputLabelProps={{
                    style: {
                      color: `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                  inputProps={{
                    style: {
                      fontWeight: `bold`,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: formErrors.usdConversionRate
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: formErrors.usdConversionRate
                          ? `solid #FF485E 1px`
                          : `solid  ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: formErrors.usdConversionRate
                          ? `#FF485E`
                          : expense?.usdConversionRate
                          ? colors.appColor
                          : `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: `none`,
                      },
                  }}
                  id="outlined-basic"
                  label="PKR-USD Rate *"
                  name="usdConversionRate"
                  variant="outlined"
                  size="small"
                  type="number"
                  value={parseFloat(expense?.usdConversionRate)}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      usdConversionRate: parseFloat(event.target.value),
                    }))
                  }
                  InputLabelProps={{
                    style: {
                      color: formErrors.usdConversionRate
                        ? `#FF485E`
                        : expense?.usdConversionRate
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <TextField
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid  ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                      {
                        display: `none`,
                      },
                  }}
                  id="outlined-basic"
                  label="USD Amount *"
                  name="usdAmount"
                  variant="outlined"
                  size="small"
                  type="number"
                  aria-readonly
                  value={(
                    parseFloat(pkrAmount) /
                    parseFloat(expense?.usdConversionRate)
                  ).toFixed(2)}
                  InputLabelProps={{
                    style: {
                      color: `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                  inputProps={{
                    style: {
                      fontWeight: `bold`,
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-multiline-static"
                  label="Description * "
                  multiline
                  rows={2}
                  value={expense.description}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      description: event.target.value,
                    }))
                  }
                  InputLabelProps={{
                    style: {
                      color: expense.description
                        ? colors.appColor
                        : `rgba(40, 32, 61, 0.5)`,
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: formErrors.description
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: formErrors.description
                          ? `solid #FF485E 1px`
                          : `solid ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: formErrors.description
                          ? `#FF485E`
                          : expense?.description
                          ? colors.appColor
                          : `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                      '& svg': { color: `#19C9D1` },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                backgroundColor: `rgba(25, 201, 209, 0.1)`,
                height: `50px`,
                marginTop: `20px`,
                borderRadius: `10px`,
                paddingY: `10px`,
                marginLeft: `10px`,
                gap: `10px`,
              }}
            >
              <Typography
                sx={{
                  color: `
  rgba(96, 57, 187, 1)`,
                  fontWeight: 400,
                  fontSize: `14px`,
                  lineHeight: `16.94px`,
                }}
              >
                Upload Receipt
              </Typography>
              <Image
                src={uploadIcon}
                width={24}
                height={24}
                sx={{
                  '&:hover': {
                    cursor: `pointer`,
                  },
                }}
                onClick={() => hiddenFileInput.current.click()}
              />
              <input
                multiple
                id="image-picker"
                accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF,.svg,.SVG,.WEBP"
                type="file"
                ref={hiddenFileInput}
                onChange={(e) => handleFileUpload(e?.target?.files)}
                style={{ display: `none` }}
                disabled={fileUploading}
              />
            </Grid>

            <Grid container spacing={2} item xs={12}>
              <Grid container item spacing={2} xs={12}>
                {mediaName &&
                  mediaName?.map((item, index) => (
                    <Grid key={index} item xs={12} sm={6}>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `row`,
                          justifyContent: `space-between`,
                          alignItems: `center`,
                          backgroundColor: `rgba(25, 201, 209, 0.1)`,
                          borderRadius: `10px`,
                          padding: `10px`,
                        }}
                      >
                        <img src={file} alt="file" />
                        <Typography
                          noWrap
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 400,
                            fontSize: `14px`,
                          }}
                        >
                          {item.fileName}
                        </Typography>

                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontWeight: 400,
                            fontSize: `14px`,
                            '&:hover': { cursor: `pointer` },
                          }}
                          onClick={() => handleCrossPhoto(item)}
                        >
                          x
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: `flex`,
              flexDirection: {
                xs: `column`,
                sm: `row`,
              },
              justifyContent: `flex-end`,
              width: `100%`,
              gap: `10px`,
              marginTop: `5px`,
            }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: `none`,

                borderRadius: `10px`,
                bgcolor: `rgba(40, 32, 61, 0.07)`,
                color: `black`,
                width: { xs: `100%`, sm: `110px` },
                height: `36px`,
                '&:hover': {
                  bgcolor: `rgba(40, 32, 61, 0.07)`,
                  cursor: `pointer`,
                  opacity: 0.8,
                },
              }}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              sx={{
                textTransform: `none`,
                borderRadius: `10px`,
                bgcolor: colors.appColor,
                color: `white`,
                width: { xs: `100%`, sm: `110px` },
                height: `36px`,
                '&:hover': {
                  bgcolor: colors.appColor,
                  cursor: `pointer`,
                  opacity: 0.8,
                },
              }}
              onClick={validateForm}
              disabled={!fund || fund?.length === 0 || isLoading}
            >
              {isLoading || fileUploading ? (
                <CircularProgress
                  size={20}
                  color="secondary"
                  style={{ position: `absolute`, color: `white` }}
                />
              ) : fileUploading ? (
                `Uploading`
              ) : (
                `Add`
              )}
            </Button>
          </Box>
        </DialogContent>
        <Divider />
      </Dialog>
    </>
  )
}

export default AddExpense
