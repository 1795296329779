import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
  TextField,
} from '@material-ui/core'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import api from 'app/AxiosInstance'
import GraphSkeleton from 'app/Components/Common/GraphSkeleton'
import { SnackbarContext } from 'app/Components/SnackbarProvider'
import { getAllAccounts } from 'app/Services/General/getAllAccounts'
import { baseUrl } from 'app/Utils/Config'
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import moment from 'moment'
import Image from 'mui-image'
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import backArrow from '../../../Assets/backwardIcon.svg'
import forwardArrow from '../../../Assets/forward.svg'

import { Divider } from '@mui/material'
// import PostpendUsd from 'app/Utils/PostpendUsd'
// import PostpendPKR from 'app/Utils/PostpendPKR'

// Seletons
import { profitLossSkeleton } from './ProfitLossSkeleton'
import formatAmountWithCommas from 'app/Utils/formatAmountWithCommas'

// utility functions
import { isDecimal } from 'app/Utils/isDecimal'

ChartJS.register(
  LogarithmicScale,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  ArcElement,
  Filler,
)

const barChartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      type: `logarithmic`,
      beginAtZero: true,
      stacked: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      top: 10,
      bottom: 20,
    },
  },
}

const options = {
  indexAxis: `x`,
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  layout: {
    padding: {
      top: 10,
      bottom: 20,
    },
  },
}

const monthNames = [
  `Jan`,
  `Feb`,
  `Mar`,
  `Apr`,
  `May`,
  `Jun`,
  `July`,
  `Aug`,
  `Sep`,
  `Oct`,
  `Nov`,
  `Dec`,
]

const getPreviousMonth = (currentMonth, currentYear) => {
  let previousMonth = currentMonth - 1
  let previousYear = currentYear

  if (previousMonth === 0) {
    previousMonth = 12
    previousYear -= 1
  }

  return {
    month: previousMonth,
    year: previousYear,
  }
}

const ExpenditureDashboard = () => {
  const openSnackbar = useContext(SnackbarContext)
  const token = localStorage.getItem(`AuthToken`)
  const colors = useSelector((state) => state?.colors)
  const systemRole = localStorage.getItem(`systemRole`)

  const [payrollChart, setPayrollChart] = useState(``)
  const [invoiceChart, setInvoiceChart] = useState(``)
  const [accountBudgetChart, setAccountBudgetChart] = useState(``)
  const [
    monthlyExpensesOfAllAccountsChart,
    setMonthlyExpensesOfAllAccountsChart,
  ] = useState(``)
  const [
    statusWiseAmountsOfAllVendorsChart,
    setStatusWiseAmountOfAllVendorsChart,
  ] = useState(``)

  const [
    allInvoicesOfAllPaymentModesChart,
    setAllInvoicesOfAllPaymentModesChart,
  ] = useState(``)
  const [transactChart, setTransactionChart] = useState(``)
  const [monthlyExpenseChart, setMonthlyExpenseChart] = useState(``)
  const [accountExpenseChart, setAccountExpenseChart] = useState(``)

  const [accountsData, setAccountsData] = useState([])

  useEffect(() => {
    getAllAccounts().then((result) => {
      const newLoadAccounts = result?.options
        ?.map((account) => {
          if (
            account?.name &&
            !account.name.toLowerCase().includes(`payroll`)
          ) {
            return {
              label: account?.name,
              value: account?._id,
            }
          }
          return null // Filter out accounts with "payroll" in their name
        })
        .filter(Boolean) // Remove null entries from the array
      setAccountsData(newLoadAccounts)
    })
  }, [])

  const { data: fundsData } = useQuery(
    `getFundsDataApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/funds?allocatedBy=&allocatedTo=&startDate=&endDate=&below=&utilized=&exceeded=`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const today = new Date()

  const [expense, setExpense] = useState({
    account: ``,
    funds: ``,
    month: today.getMonth() + 1,
    year: today.getFullYear(),
    payrollMonth: today.getMonth() + 1,
    payrollYear: today.getFullYear(),
    invoiceMonth: today.getMonth() + 1,
    invoiceYear: today.getFullYear(),
    profitLossMonth: today.getMonth() + 1,
    profitLossYear: today.getFullYear(),
  })

  useEffect(() => {
    if (accountsData) {
      setExpense((prev) => ({
        ...prev,
        account: accountsData?.[0]?.value,
      }))
    }
  }, [accountsData])
  useEffect(() => {
    if (fundsData) {
      setExpense((prev) => ({
        ...prev,
        funds: fundsData?.[0]?._id,
      }))
    }
  }, [fundsData])

  const { data: payrollData, isLoading: payrollLoader } = useQuery(
    `getAllPayrollData`,
    async () =>
      await api
        .get(`${baseUrl}/dashboard/payrollAccountBudgets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const data = res?.data?.data?.budget

          return data?.slice(0, 6) // Get data of the first six indexes
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const { data: accountsBudget, isLoading: accountsBudgetLoader } = useQuery(
    `getAllAccountsBudget`,
    async () =>
      await api
        .get(`${baseUrl}/dashboard/allAccountsBudgets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const filteredData = res?.data?.data.filter(
            (account) => account?.account?.toLowerCase() !== `payroll`,
          )

          return [...filteredData]
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const [
    monthlyExpensesOfAllAccountsDate,
    setMonthlyExpensesOfAllAccountsDate,
  ] = useState(moment(new Date()).format(`YYYY-MM`))
  // today.getMonth() + 1 <= 9
  //   ? `${today.getFullYear()}-0${today.getMonth() + 1}`
  //   : `${today.getFullYear()}-${today.getMonth() + 1}`,

  const handleMonthlyExpensesOfAllAccountsDateChange = (date) => {
    setMonthlyExpensesOfAllAccountsDate(date)
  }

  const {
    data: monthlyExpensesOffAllAccounts,
    isLoading: monthlyExpensesOffAllAccountsLoader,
    isFetching: monthlyExpensesOffAllAccountsFetching,
    refetch: refetchMonthlyExpensesOfAllAccounts,
  } = useQuery(
    `monthlyExpensesOffAllAccounts`,
    async () =>
      await api
        .get(
          `/dashboard/getMonthlyExpensesOfAllAccounts?month=${moment(
            monthlyExpensesOfAllAccountsDate,
          ).format(`YYYY-MM`)}`,
        )
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            return res.data.data
          } else {
            return []
          }
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const statusWiseAmountsOfAllVendorsQuery =
    systemRole === `CEO`
      ? useQuery(
          `statusWiseAmountsOfAllVendors`,
          async () =>
            await api
              .get(`/dashboard/getStatusWiseAmountsOfAllVendors`)
              .then((res) => {
                if (res?.data?.data?.length > 0) {
                  return res?.data?.data
                } else {
                  return []
                }
              }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
            onError: (error) => {
              openSnackbar(
                error?.response
                  ? error?.response?.data?.message
                  : error?.message,
                `error`,
              )
            },
          },
        )
      : null

  const {
    data: statusWiseAmountsOfAllVendors,
    isLoading: statusWiseAmountsOfAllVendorsLoading,
    isFetching: statusWiseAmountsOfAllVendorsFetching,
  } = statusWiseAmountsOfAllVendorsQuery || []

  const allInvoicesAmountOfAllPaymentModesQuery =
    systemRole === `CEO`
      ? useQuery(
          `allInvoicesAmountOfAllPaymentModes`,
          async () =>
            await api
              .get(`/dashboard/getAllInvoicesAmountOfAllPaymentModes`)
              .then((res) => {
                if (res?.data?.data?.length > 0) {
                  return res?.data?.data
                } else {
                  return []
                }
              }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
            onError: (error) => {
              openSnackbar(
                error?.response
                  ? error?.response?.data?.message
                  : error?.message,
                `error`,
              )
            },
          },
        )
      : null

  const {
    data: allInvoicesAmountOfAllPaymentModes,
    isLoading: allInvoicesAmountOfAllPaymentModesLoading,
    isFetching: allInvoicesAmountOfAllPaymentModesFetching,
  } = allInvoicesAmountOfAllPaymentModesQuery || []

  const profitLossDataQuery =
    systemRole === `CEO`
      ? useQuery(
          `profitLoss`,
          async () => {
            try {
              const res = await api.get(
                `/invoices/monthly/profit-loss?month=${
                  expense.profitLossYear
                }-${
                  expense.profitLossMonth <= 9
                    ? `0${expense.profitLossMonth}`
                    : expense.profitLossMonth
                }`,
              )

              return res?.data?.data || {} // Return data or an empty object if no data is present
            } catch (error) {
              openSnackbar(error, 'error')
              throw error
            }
          },
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
            onError: (error) => {
              openSnackbar(
                error?.response
                  ? error?.response?.data?.message
                  : error?.message,
                `error`,
              )
            },
          },
        )
      : null

  const {
    data: profitLossData,
    isLoading: profitLossDataLoading,
    isFetching: profitLossDataFetching,
    refetch: refetchProfitLoss,
  } = profitLossDataQuery || {}

  useEffect(() => {
    if (expense?.profitLossMonth && profitLossDataQuery) {
      refetchProfitLoss()
    }
  }, [expense?.profitLossMonth])

  const { data: monthlyExpense, isLoading: monthlyExpenseLoader } = useQuery(
    `getAllMonthlyExpense`,
    async () =>
      await api
        .get(`${baseUrl}/dashboard/monthlyExpensesOfAllAccounts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const {
    mutate: handleAccountExpense,
    data: accountExpense,
    isLoading: accountExpenseLoader,
    isFetching: fetchAccountExpense,
  } = useMutation(
    `getAccountsExpense`,
    async () =>
      await api
        .get(
          `${baseUrl}/dashboard/monthlyExpensesOfSingleAccounts/${
            expense?.account ? expense?.account : ``
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          return res?.data?.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const {
    mutate: handleTransactionData,
    data: transactionsData,
    isLoading: transactionsDataLoader,
    isFetching: transactionsDataFetching,
  } = useMutation(
    `getTransactionData`,
    async () =>
      await api
        .get(
          `${baseUrl}/dashboard/getTransactionsOfSingleFund/${expense?.funds}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          const data = res?.data?.data
          return data?.slice(0, 6)
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const {
    data: monthlyExpenseAmount,
    isLoading: monthlyExpenseAmountLoader,
    refetch: refetchMonthlyExpenseAmount,
    isFetching: fetchMonthlyExpenseAmount,
  } = useQuery(
    `getAllMonthlyExpenseAmountApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/dashboard/getMonthlyExpenses?month=${
            expense?.year
          }-${expense?.month.toString().padStart(2, `0`)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          if (res?.data?.data) {
            return res?.data?.data
          }
          return {}
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const {
    data: monthlyPayrollExpenseAmount,
    isLoading: monthlyPayrollExpenseAmountLoader,
    refetch: refetchMonthlyExpensePayrollAmount,
    isFetching: fetchMonthlyExpensePayrollAmount,
  } = useQuery(
    `getAllMonthlyExpensePayrollAmountApi`,
    async () =>
      await api
        .get(
          `${baseUrl}/dashboard/getMonthlyPayrollExpensesAmount?month=${
            expense?.payrollYear
          }-${expense?.payrollMonth.toString().padStart(2, `0`)}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          if (res?.data?.data) {
            return res?.data?.data
          }
          return {}
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,

      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  const [project, setProject] = useState(``)
  const [
    allInvoicesAmountOfSingleProjectChart,
    setAllInvoicesAmountOfSingleProjectChart,
  ] = useState(``)

  const handleProjectsChange = (value) => {
    setProject(value)
  }

  const listProjectsQuery =
    systemRole === `CEO`
      ? useQuery(
          `listProjects`,
          async () => {
            const { data } = await api.get(`/projects/listProjects`)
            setProject(data?.data?.[0]?._id)
            return data?.data
          },
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
            onError: (error) => {
              openSnackbar(
                error?.response ? error.response.data.message : error.message,
                `error`,
              )
            },
          },
        )
      : null

  const { data: listProjects } = listProjectsQuery || []

  const {
    mutate: mutateAllInvoicesAmountOfSingleProject,
    data: allInvoicesAmountOfSingleProject,
    isLoading: allInvoicesAmountOfSingleProjectLoading,
    isFetching: allInvoicesAmountOfSingleProjectFetching,
  } = useMutation(
    `allInvoicesAmountOfSingleProject`,
    async () =>
      await api
        .get(`/dashboard/getAllInvoicesAmountOfSingleProject/${project}`)
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            return res?.data?.data
          } else {
            return []
          }
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  useEffect(() => {
    if (allInvoicesAmountOfSingleProject?.length > 0) {
      const labels = []
      const pkrAmount = []
      const usdAmount = []

      allInvoicesAmountOfSingleProject &&
        allInvoicesAmountOfSingleProject?.forEach((project) => {
          labels.push(project?.project)
          project?.amountAndStatus?.forEach((obj) => {
            pkrAmount.push(obj?.pkrAmount)
            usdAmount.push(obj?.usdAmount)
          })
        })

      const data = {
        labels,
        datasets: [
          {
            label: `USD Amount`,
            data: usdAmount,
            backgroundColor: [`#DC84F3`],
            borderWidth: 1,
          },
          {
            label: `PKR Amount`,
            data: pkrAmount,
            backgroundColor: [`#E9A8F2`],
            borderWidth: 1,
          },
        ],
      }

      setAllInvoicesAmountOfSingleProjectChart(data)
    } else {
      setAllInvoicesAmountOfSingleProjectChart({ labels: [], datasets: [] })
    }
  }, [allInvoicesAmountOfSingleProject])

  useEffect(() => {
    if (project) {
      mutateAllInvoicesAmountOfSingleProject()
    }
  }, [project])

  const [client, setClient] = useState(``)
  const [
    allInvoicesAmountOfSingleClientChart,
    setAllInvoicesAmountOfSingleClientChart,
  ] = useState()

  const handleClientsChange = (value) => {
    setClient(value)
  }

  const listClientsQuery =
    systemRole === `CEO`
      ? useQuery(
          `listClients`,
          async () => {
            const { data } = await api.get(`/clients`)
            setClient(data?.data?.[0]?._id)
            return data?.data
          },
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
            onError: (error) => {
              openSnackbar(
                error?.response ? error.response.data.message : error.message,
                `error`,
              )
            },
          },
        )
      : null

  const { data: listClients } = listClientsQuery || []

  const {
    mutate: mutateAllInvoicesAmountOfSingleClient,
    data: allInvoicesAmountOfSingleClient,
    isLoading: allInvoicesAmountOfSingleClientLoading,
    isFetching: allInvoicesAmountOfSingleClientFetching,
  } = useMutation(
    `allInvoicesAmountOfSingleClient`,
    async () =>
      await api
        .get(`/dashboard/getAllInvoicesAmountOfSingleClient/${client}`)
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            return res?.data?.data
          } else {
            return []
          }
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onError: (error) => {
        openSnackbar(
          error?.response ? error?.response?.data?.message : error?.message,
          `error`,
        )
      },
    },
  )

  useEffect(() => {
    if (allInvoicesAmountOfSingleClient?.length > 0) {
      const labels = []
      const pkrAmount = []
      const usdAmount = []

      allInvoicesAmountOfSingleClient &&
        allInvoicesAmountOfSingleClient?.forEach((client) => {
          labels.push(client?.client)
          client?.amountAndStatus?.forEach((obj) => {
            pkrAmount.push(obj?.pkrAmount)
            usdAmount.push(obj?.usdAmount)
          })
        })
      const data = {
        labels,
        datasets: [
          {
            label: `USD Amount`,
            data: usdAmount,
            backgroundColor: [`#643843`],
            borderWidth: 1,
          },
          {
            label: `PKR Amount`,
            data: pkrAmount,
            backgroundColor: [`#85586F`],
            borderWidth: 1,
          },
        ],
      }

      setAllInvoicesAmountOfSingleClientChart(data)
    } else {
      setAllInvoicesAmountOfSingleClientChart({ labels: [], datasets: [] })
    }
  }, [allInvoicesAmountOfSingleClient])

  useEffect(() => {
    if (client) {
      mutateAllInvoicesAmountOfSingleClient()
    }
  }, [client])

  const [lastSixMonthsProfitLostChart, setLastSixMonthsProfitLostChart] =
    useState()

  const lastSixMonthsProfitLostQuery =
    systemRole === `CEO`
      ? useQuery(
          `lastSixMonthsProfitLost`,
          async () => {
            const { data } = await api.get(`/invoices/month-wise/profit-loss`)
            return data?.data
          },
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
            onError: (error) => {
              openSnackbar(
                error?.response ? error.response.data.message : error.message,
                `error`,
              )
            },
          },
        )
      : null

  const {
    data: lastSixMonthsProfitLost,
    isLoading: lastSixMonthsProfitLostLoading,
    isFetching: lastSixMonthsProfitLostFetching,
  } = lastSixMonthsProfitLostQuery || {}

  useEffect(() => {
    if (lastSixMonthsProfitLost?.length > 0) {
      const labels = []

      const expensePkr = []
      const expenseUsd = []

      const incomePkr = []
      const incomeUsd = []

      const lossPctPkr = []
      const lossPctUsd = []

      const lossPkr = []
      const lossUsd = []

      const profitPctPkr = []
      const profitPctUsd = []

      const profitPkr = []
      const profitUsd = []

      lastSixMonthsProfitLost &&
        lastSixMonthsProfitLost?.forEach((invoice) => {
          labels.push(invoice?.month)

          incomePkr.push(invoice?.income_pkr)
          expensePkr.push(invoice?.expense_pkr)

          incomeUsd.push(invoice?.income_usd)
          expenseUsd.push(invoice?.expense_usd)

          profitPkr.push(invoice?.profit_pkr)
          profitUsd.push(invoice?.profit_usd)

          profitPctPkr.push(invoice?.profit_pct_pkr)
          profitPctUsd.push(invoice?.profit_pct_usd)

          lossPkr.push(invoice?.loss_pkr)
          lossUsd.push(invoice?.loss_usd)

          lossPctPkr.push(invoice?.loss_pct_pkr)
          lossPctUsd.push(invoice?.loss_pct_usd)
        })
      const data = {
        labels,
        datasets: [
          {
            label: `Income-RS`,
            data: incomePkr,
            backgroundColor: `#211C6A`,
          },
          {
            label: `Expense-RS`,
            data: expensePkr,
            backgroundColor: `#59B4C3`,
          },
          {
            label: `Income-$`,
            data: incomeUsd,
            backgroundColor: `#74E291`,
          },
          {
            label: `Expense-$`,
            data: expenseUsd,
            backgroundColor: `#EFF396`,
          },
          {
            label: `Profit-RS`,
            data: profitPkr,
            backgroundColor: `#7F27FF`,
          },
          {
            label: `Profit-$`,
            data: profitUsd,
            backgroundColor: `#9F70FD`,
          },
          {
            label: `Profit-%`,
            data: profitPctPkr,
            backgroundColor: `#FDBF60`,
          },
          {
            label: `Loss-RS`,
            data: lossPkr,
            backgroundColor: `#FF8911`,
          },
          {
            label: `Loss-$`,
            data: lossUsd,
            backgroundColor: `#9B4444`,
          },
          {
            label: `Loss-%`,
            data: lossPctPkr,
            backgroundColor: `#C68484`,
          },
        ],
      }

      setLastSixMonthsProfitLostChart(data)
    } else {
      setLastSixMonthsProfitLostChart({ labels: [], datasets: [] })
    }
  }, [lastSixMonthsProfitLost])

  const clientQuery =
    systemRole === `CEO`
      ? useQuery(
          `getClientsApi`,
          async () =>
            await api
              .get(`${baseUrl}/dashboard/clients`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => {
                return res?.data?.data
              }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,

            onError: (error) => {
              openSnackbar(
                error?.response
                  ? error?.response?.data?.message
                  : error?.message,
                `error`,
              )
            },
          },
        )
      : null

  const { data: clientsData } = clientQuery || {}

  const InvoicesQuery =
    systemRole === `CEO`
      ? useQuery(
          `getInvoiceApi`,
          async () =>
            await api
              .get(
                `${baseUrl}/dashboard/invoices?month=${
                  expense?.invoiceYear
                }-${expense?.invoiceMonth.toString().padStart(2, `0`)}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              )
              .then((res) => {
                return res?.data?.data
              }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,

            onError: (error) => {
              openSnackbar(
                error?.response
                  ? error?.response?.data?.message
                  : error?.message,
                `error`,
              )
            },
          },
        )
      : null

  const {
    data: invoiceData,
    isLoading: invoiceLoader,
    isFetching: fetchInvoice,
    refetch: refetchInvoice,
  } = InvoicesQuery || {}

  useEffect(() => {
    if (payrollData) {
      const labels = []
      const balances = []

      payrollData &&
        payrollData?.forEach((payroll) => {
          const month = new Date(payroll.startDate).toLocaleString(`default`, {
            month: `long`,
          })
          labels.push(month)
          balances.push(payroll.total)
        })
      const data = {
        labels,
        datasets: [
          {
            data: balances,
            backgroundColor: [`#2D9596`],

            borderWidth: 1,
          },
        ],
      }

      setPayrollChart(data)
    }
  }, [payrollData])

  useEffect(() => {
    if (invoiceData?.length > 0) {
      const labels = []
      const pkrAmount = []
      const usdAmount = []

      invoiceData &&
        invoiceData?.forEach((invoice) => {
          labels.push(invoice?.status)
          usdAmount.push(invoice?.usdAmount)
          pkrAmount.push(invoice?.pkrAmount)
        })

      const data = {
        labels,
        datasets: [
          {
            label: [`USD Amount`],
            data: usdAmount,
            backgroundColor: [`#9BCF53`],
            borderWidth: 1,
          },
          {
            label: [`PKR Amount`],
            data: pkrAmount,
            backgroundColor: [`#416D19`],
            borderWidth: 1,
          },
        ],
      }

      setInvoiceChart(data)
    } else {
      setInvoiceChart({ labels: [], datasets: [] })
    }
  }, [invoiceData])

  useEffect(() => {
    if (statusWiseAmountsOfAllVendors?.length > 0) {
      const labels = []
      const pkrAmount = []
      const usdAmount = []

      statusWiseAmountsOfAllVendors &&
        statusWiseAmountsOfAllVendors?.forEach((vendor) => {
          labels.push(vendor?.business)
          vendor?.statusWiseAmounts?.forEach((obj) => {
            pkrAmount.push(obj?.pkrAmount)
            usdAmount.push(obj?.usdAmount)
          })
        })
      const data = {
        labels,
        datasets: [
          {
            label: [`USD Amount`],
            data: usdAmount,
            backgroundColor: [`#FF407D`],
            borderWidth: 1,
          },
          {
            label: [`PKR Amount`],
            data: pkrAmount,
            backgroundColor: [`#FFCAD4`],
            borderWidth: 1,
          },
        ],
      }

      setStatusWiseAmountOfAllVendorsChart(data)
    } else {
      setStatusWiseAmountOfAllVendorsChart({ labels: [], datasets: [] })
    }
  }, [statusWiseAmountsOfAllVendors])

  useEffect(() => {
    if (accountsBudget) {
      const labels = []
      const balances = []
      const totalBudget = []
      const utilizedBudget = []

      accountsBudget &&
        accountsBudget?.forEach((budget) => {
          labels.push(budget?.account)
          balances.push(budget?.remaining)
          totalBudget.push(budget?.total)
          utilizedBudget.push(budget?.utilized)
        })
      const data = {
        labels,
        datasets: [
          {
            label: `Total Budget`,
            data: totalBudget,
            backgroundColor: [`#4682A9`],
            borderWidth: 1,
          },
          {
            label: `Utilized`,
            data: utilizedBudget,
            backgroundColor: [`#749BC2`],
            borderWidth: 1,
          },
          {
            label: `Remaining`,
            data: balances,
            backgroundColor: [`#91C8E4`],
            borderWidth: 1,
          },
        ],
      }

      setAccountBudgetChart(data)
    }
  }, [accountsBudget])

  useEffect(() => {
    if (monthlyExpensesOffAllAccounts?.length > 0) {
      const labels = []
      const pkrAmount = []
      const usdAmount = []

      monthlyExpensesOffAllAccounts &&
        monthlyExpensesOffAllAccounts?.forEach((expense) => {
          labels.push(expense?.account)
          pkrAmount.push(expense?.pkrAmount)
          usdAmount.push(expense?.usdAmount)
        })
      const data = {
        labels,
        datasets: [
          {
            label: `USD Amount`,
            data: usdAmount,
            backgroundColor: [`#B784B7`],
            borderWidth: 1,
          },
          {
            label: `PKR Amount`,
            data: pkrAmount,
            backgroundColor: [`#8E7AB5`],
            borderWidth: 1,
          },
        ],
      }

      setMonthlyExpensesOfAllAccountsChart(data)
    } else {
      setMonthlyExpensesOfAllAccountsChart({ labels: [], datasets: [] })
    }
  }, [monthlyExpensesOffAllAccounts])

  useEffect(() => {
    if (allInvoicesAmountOfAllPaymentModes?.length > 0) {
      const labels = []
      const pkrAmount = []
      const usdAmount = []

      allInvoicesAmountOfAllPaymentModes &&
        allInvoicesAmountOfAllPaymentModes?.forEach((invoice) => {
          labels.push(invoice?.paymentMode)
          invoice?.amountAndStatus?.forEach((obj) => {
            pkrAmount.push(obj?.pkrAmount)
            usdAmount.push(obj?.usdAmount)
          })
        })
      const data = {
        labels,
        datasets: [
          {
            label: `USD Amount`,
            data: usdAmount,
            backgroundColor: [`#E6A4B4`],
            borderWidth: 1,
          },
          {
            label: `PKR Amount`,
            data: pkrAmount,
            backgroundColor: [`#F3D7CA`],
            borderWidth: 1,
          },
        ],
      }

      setAllInvoicesOfAllPaymentModesChart(data)
    } else {
      setAllInvoicesOfAllPaymentModesChart({ labels: [], datasets: [] })
    }
  }, [allInvoicesAmountOfAllPaymentModes])

  useEffect(() => {
    if (transactionsData) {
      const labels = []
      const balances = []

      transactionsData &&
        transactionsData?.forEach((transaction) => {
          const month = moment(transaction?.transactionMonth).format(`MMM`)
          labels.push(month)
          balances.push(transaction?.transactionsAmount)
        })
      const data = {
        labels,
        datasets: [
          {
            data: balances,
            backgroundColor: [`#9195F6`],
            borderWidth: 1,
          },
        ],
      }

      setTransactionChart(data)
    }
  }, [transactionsData])

  useEffect(() => {
    const labels = []
    const usdAmount = []
    const pkrAmount = []
    monthlyExpense &&
      monthlyExpense?.forEach((expense) => {
        labels.push(moment(expense?.expenseMonth).format(`MMM`))
        usdAmount.push(expense?.usdAmount)
        pkrAmount.push(expense?.pkrAmount)
      })
    const data = {
      labels,
      datasets: [
        {
          label: `USD Amount`,
          data: usdAmount,
          backgroundColor: `#86A7FC`,
          fill: true,
          borderWidth: 1,
        },
        {
          label: `PKR Amount`,
          data: pkrAmount,
          backgroundColor: `#3468C0`,
          fill: true,
          borderWidth: 1,
        },
      ],
    }
    setMonthlyExpenseChart(data)
  }, [monthlyExpense])

  useEffect(() => {
    let labels = []
    let usdAmount = []
    let pkrAmount = []
    accountExpense &&
      accountExpense?.forEach((expense) => {
        labels.push(moment(expense?.month).format(`MMM`))
        usdAmount.push(expense?.usdAmount)
        pkrAmount.push(expense?.pkrAmount)
      })
    const data = {
      labels,
      datasets: [
        {
          data: usdAmount,
          backgroundColor: `#C68484`,
          fill: true,
          borderWidth: 1,
        },
        {
          data: pkrAmount,
          backgroundColor: `#9B4444`,
          fill: true,
          borderWidth: 1,
        },
      ],
    }
    setAccountExpenseChart(data)
  }, [accountExpense])

  useEffect(() => {
    if (expense?.funds) handleTransactionData()
  }, [expense?.funds])

  useEffect(() => {
    if (expense?.invoiceMonth && systemRole !== `HRM`) refetchInvoice()
  }, [expense?.invoiceMonth])

  useEffect(() => {
    if (expense?.account) handleAccountExpense()
  }, [expense?.account])

  useEffect(() => {
    if (expense?.month) refetchMonthlyExpenseAmount()
  }, [expense?.month])

  useEffect(() => {
    if (expense?.payrollMonth) refetchMonthlyExpensePayrollAmount()
  }, [expense?.payrollMonth])

  useEffect(() => {
    if (
      monthlyExpensesOfAllAccountsDate &&
      monthlyExpensesOfAllAccountsDate !== `Invalid date`
    )
      refetchMonthlyExpensesOfAllAccounts()
  }, [monthlyExpensesOfAllAccountsDate])

  const handleBackward = () => {
    const previousMonthData = getPreviousMonth(expense.month, expense.year)
    setExpense({
      ...expense,
      month: previousMonthData.month,
      year: previousMonthData.year,
    })
  }

  const handleForward = () => {
    if (expense.month === 12) {
      setExpense({
        ...expense,
        month: 1,
        year: expense.year + 1,
      })
    } else {
      setExpense({
        ...expense,
        month: expense.month + 1,
        year: expense.year,
      })
    }
  }

  const handlePayrollBackward = () => {
    const previousMonthData = getPreviousMonth(
      expense.payrollMonth,
      expense.payrollYear,
    )
    setExpense({
      ...expense,
      payrollMonth: previousMonthData.month,
      payrollYear: previousMonthData.year,
    })
  }

  const handlePayrollForward = () => {
    if (expense.payrollMonth === 12) {
      setExpense({
        ...expense,
        payrollMonth: 1,
        payrollYear: expense.payrollYear + 1,
      })
    } else {
      setExpense({
        ...expense,
        payrollMonth: expense.payrollMonth + 1,
        payrollYear: expense.payrollYear,
      })
    }
  }

  const handleInvoiceBackward = () => {
    const previousMonthData = getPreviousMonth(
      expense.invoiceMonth,
      expense.invoiceYear,
    )
    setExpense({
      ...expense,
      invoiceMonth: previousMonthData.month,
      invoiceYear: previousMonthData.year,
    })
  }

  const handleInvoiceForward = () => {
    if (expense.invoiceMonth === 12) {
      setExpense({
        ...expense,
        invoiceMonth: 1,
        invoiceYear: expense.invoiceYear + 1,
      })
    } else {
      setExpense({
        ...expense,
        invoiceMonth: expense.invoiceMonth + 1,
        invoiceYear: expense.invoiceYear,
      })
    }
  }

  const handleProfitLossBackward = () => {
    const previousMonthData = getPreviousMonth(
      expense.profitLossMonth,
      expense.profitLossYear,
    )
    setExpense({
      ...expense,
      profitLossMonth: previousMonthData.month,
      profitLossYear: previousMonthData.year,
    })
  }

  const handleProfitLossForward = () => {
    if (expense.profitLossMonth === 12) {
      setExpense({
        ...expense,
        profitLossMonth: 1,
        profitLossYear: expense.profitLossYear + 1,
      })
    } else {
      setExpense({
        ...expense,
        profitLossMonth: expense.profitLossMonth + 1,
        profitLossYear: expense.profitLossYear,
      })
    }
  }

  const [totalClients, setTotalClients] = useState(0)
  const [activeClients, setActiveClients] = useState(``)
  const [inActiveClients, setInActiveClients] = useState(``)
  useEffect(() => {
    if (clientsData) {
      setTotalClients(
        clientsData?.reduce(
          (sum, client) => sum + (client?.totalClients || 0),
          0,
        ),
      )
    }
    setActiveClients(
      clientsData?.filter((client) => client?.status !== `INACTIVE`),
    )
    setInActiveClients(
      clientsData?.filter((client) => client?.status !== `ACTIVE`),
    )
  }, [clientsData])

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: `10px`,
        paddingLeft: { xs: `10px`, sm: `20px` },
        paddingRight: { xs: `10px`, sm: `20px` },
        paddingBottom: { xs: `10px`, sm: `20px` },
        paddingTop: `0px`,
        minHeight: `100vh`,
        backgroundColor: `rgba(40, 32, 61, 0.07)`,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} lg={3}>
          <Box
            sx={{
              borderRadius: `10px`,
              backgroundColor: `white`,
              height: `130px`,
              padding: `20px`,
            }}
          >
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontSize: `16px`,
                fontWeight: 600,
                lineHeight: `16.94px`,
                color: `rgba(40, 32, 61, 0.6)`,
              }}
            >
              Total Accounts
            </Typography>
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontSize: `24px`,
                fontWeight: 600,
                lineHeight: `29.05px`,
                color: `rgba(40, 32, 61, 1)`,
                paddingTop: `20px`,
              }}
            >
              {accountsData ? accountsData?.length : 0}
            </Typography>
          </Box>
        </Grid>

        {systemRole !== `HRM` && systemRole !== `EMPLOYEE` && (
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                borderRadius: `10px`,
                backgroundColor: `white`,
                height: `130px`,
                padding: `20px`,
              }}
            >
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  fontSize: `16px`,
                  fontWeight: 600,
                  lineHeight: `16.94px`,
                  color: `rgba(40, 32, 61, 0.6)`,
                }}
              >
                Clients
              </Typography>
              <Box
                sx={{
                  height: `10px`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                  width: `100%`,
                  marginY: `20px`,
                  // marginBottom: `10px`,
                  borderRadius: `3px`,
                  background:
                    activeClients?.length > 0 && inActiveClients?.length > 0
                      ? `linear-gradient(90deg, #19C9D1 ${
                          activeClients
                            ? (activeClients?.[0]?.totalClients /
                                totalClients) *
                              100
                            : 0
                        }%, #FF485E ${
                          inActiveClients
                            ? (inActiveClients?.[0]?.totalClients /
                                totalClients) *
                              100
                            : 0
                        }%)`
                      : activeClients?.length > 0
                      ? `#19C9D1`
                      : `#FF485E`,
                }}
              ></Box>
              <Box
                sx={{
                  display: `flex`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Typography
                  sx={{
                    display: `flex`,
                    alignItems: `center`,
                    gap: `10px`,
                    color: `#28203D`,
                    fontFamily: `Inter`,
                    fontSize: `16px`,
                    fontWeight: 600,
                    lineHeight: `16.94px`,
                  }}
                >
                  Active
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      color: `#19C9D1`,
                      fontSize: `16px`,
                      fontWeight: 500,
                      lineHeight: `16.94px`,
                    }}
                  >{`(${
                    (activeClients && activeClients?.[0]?.totalClients) || 0
                  })`}</Typography>
                </Typography>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    alignItems: `center`,
                    gap: `10px`,
                    color: `#28203D`,
                    fontSize: `16px`,
                    fontWeight: 600,
                    lineHeight: `16.94px`,
                  }}
                >
                  Inactive
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      color: `#FF485E`,
                      fontSize: `16px`,
                      fontWeight: 500,
                      lineHeight: `16.94px`,
                    }}
                  >{`(${
                    (inActiveClients && inActiveClients?.[0]?.totalClients) || 0
                  })`}</Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={6} lg={3}>
          <Box
            sx={{
              borderRadius: `10px`,
              backgroundColor: `white`,
              height: `130px`,
              padding: `20px`,
            }}
          >
            <>
              <Box
                sx={{
                  borderRadius: `10px`,
                  backgroundColor: `white`,
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `16px`,
                    fontWeight: 600,
                    lineHeight: `16.94px`,
                    color: `rgba(40, 32, 61, 0.6)`,
                  }}
                >
                  Total Expense
                </Typography>
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `row`,
                    alignItems: `center`,
                    gap: `10px`,
                  }}
                >
                  <Image
                    src={backArrow}
                    width={12}
                    height={12}
                    onClick={handleBackward}
                    sx={{ cursor: `pointer` }}
                  />
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `14px`,
                      fontWeight: 500,
                      lineHeight: `14.52px`,
                    }}
                  >{`${monthNames[expense?.month - 1]} ${
                    expense?.year
                  }`}</Typography>

                  <Image
                    src={forwardArrow}
                    width={12}
                    height={12}
                    onClick={handleForward}
                    sx={{ cursor: `pointer` }}
                  />
                </Box>
              </Box>
              {monthlyExpenseAmountLoader || fetchMonthlyExpenseAmount ? (
                <Skeleton
                  sx={{
                    bgcolor: `rgba(40, 32, 61, 0.05)`,
                    height: `25%`,
                    width: `100%`,
                    marginTop: `25px`,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    marginTop: `25px`,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      lineHeight: `29.05px`,
                    }}
                  >
                    {formatAmountWithCommas(
                      Object.keys(monthlyExpenseAmount)?.length > 0
                        ? monthlyExpenseAmount?.pkrAmount % 1 !== 0
                          ? monthlyExpenseAmount?.pkrAmount?.toFixed(2)
                          : monthlyExpenseAmount?.pkrAmount
                        : 0,
                    )}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      lineHeight: `29.05px`,
                    }}
                  >
                    PKR
                  </Typography>
                </Box>
              )}

              {monthlyExpenseAmountLoader || fetchMonthlyExpenseAmount ? (
                <Skeleton
                  sx={{
                    bgcolor: `rgba(40, 32, 61, 0.05)`,
                    height: `25%`,
                    width: `100%`,
                  }}
                />
              ) : (
                <Box sx={{ display: `flex`, justifyContent: `space-between` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      lineHeight: `29.05px`,
                    }}
                  >
                    {formatAmountWithCommas(
                      Object.keys(monthlyExpenseAmount)?.length > 0
                        ? monthlyExpenseAmount?.usdAmount % 1 !== 0
                          ? monthlyExpenseAmount?.usdAmount?.toFixed(2)
                          : monthlyExpenseAmount?.usdAmount
                        : 0,
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      lineHeight: `29.05px`,
                    }}
                  >
                    USD
                  </Typography>
                </Box>
                // PostpendUsd({
                //   amount:
                //     Object.keys(monthlyExpenseAmount)?.length > 0
                //       ? monthlyExpenseAmount?.usdAmount % 1 !== 0
                //         ? monthlyExpenseAmount?.usdAmount?.toFixed(2)
                //         : monthlyExpenseAmount?.usdAmount
                //       : 0,
                //   sx: {
                //     fontSize: `16px`,
                //     fontWeight: 600,
                //     lineHeight: `29.05px`,
                //   },
                // })
              )}
            </>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <Box
            sx={{
              borderRadius: `10px`,
              backgroundColor: `white`,
              height: `130px`,
              padding: `20px`,
            }}
          >
            <>
              <Box
                sx={{
                  borderRadius: `10px`,
                  backgroundColor: `white`,
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `16px`,
                    fontWeight: 600,
                    lineHeight: `16.94px`,
                    color: `rgba(40, 32, 61, 0.6)`,
                  }}
                >
                  Payroll Expense
                </Typography>
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `row`,
                    alignItems: `center`,
                    gap: `10px`,
                  }}
                >
                  <Image
                    src={backArrow}
                    width={12}
                    height={12}
                    onClick={handlePayrollBackward}
                    sx={{ cursor: `pointer` }}
                  />
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `14px`,
                      fontWeight: 500,
                      lineHeight: `14.52px`,
                    }}
                  >{`${monthNames[expense?.payrollMonth - 1]} ${
                    expense?.payrollYear
                  }`}</Typography>

                  <Image
                    src={forwardArrow}
                    width={12}
                    height={12}
                    onClick={handlePayrollForward}
                    sx={{ cursor: `pointer` }}
                  />
                </Box>
              </Box>
              {monthlyPayrollExpenseAmountLoader ||
              fetchMonthlyExpensePayrollAmount ? (
                <Skeleton
                  sx={{
                    bgcolor: `rgba(40, 32, 61, 0.05)`,
                    height: `25%`,
                    width: `100%`,
                    marginTop: `25px`,
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: `flex`,
                    justifyContent: `space-between`,
                    marginTop: `25px`,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      color: `rgba(40, 32, 61, 1)`,
                    }}
                  >
                    {formatAmountWithCommas(
                      Object.keys(monthlyPayrollExpenseAmount)?.length > 0
                        ? monthlyPayrollExpenseAmount?.pkrAmount % 1 !== 0
                          ? monthlyPayrollExpenseAmount?.pkrAmount?.toFixed(2)
                          : monthlyPayrollExpenseAmount?.pkrAmount
                        : 0,
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      color: `rgba(40, 32, 61, 1)`,
                    }}
                  >
                    PKR
                  </Typography>
                </Box>
              )}

              {monthlyPayrollExpenseAmountLoader ||
              fetchMonthlyExpensePayrollAmount ? (
                <Skeleton
                  sx={{
                    bgcolor: `rgba(40, 32, 61, 0.05)`,
                    height: `25%`,
                    width: `100%`,
                  }}
                />
              ) : (
                <Box sx={{ display: `flex`, justifyContent: `space-between` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      color: `rgba(40, 32, 61, 1)`,
                    }}
                  >
                    {formatAmountWithCommas(
                      Object.keys(monthlyPayrollExpenseAmount)?.length > 0
                        ? monthlyPayrollExpenseAmount?.usdAmount % 1 !== 0
                          ? monthlyPayrollExpenseAmount?.usdAmount?.toFixed(2)
                          : monthlyPayrollExpenseAmount?.usdAmount
                        : 0,
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `16px`,
                      fontWeight: 600,
                      color: `rgba(40, 32, 61, 1)`,
                    }}
                  >
                    USD
                  </Typography>
                </Box>
                // PostpendUsd({
                //   amount:
                //     Object.keys(monthlyPayrollExpenseAmount)?.length > 0
                //       ? monthlyPayrollExpenseAmount?.usdAmount % 1 !== 0
                //         ? monthlyPayrollExpenseAmount?.usdAmount?.toFixed(2)
                //         : monthlyPayrollExpenseAmount?.usdAmount
                //       : 0,
                //   sx: {
                //     fontSize: `16px`,
                //     fontWeight: 600,
                //     color: `rgba(40, 32, 61, 1)`,
                //   },
                // })
              )}
            </>
            {/* )} */}
          </Box>
        </Grid>

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            {profitLossDataFetching || profitLossDataLoading ? (
              profitLossSkeleton
            ) : Object.keys(profitLossData)?.length > 0 ? (
              <Box
                sx={{
                  width: `100%`,
                  height: `100%`,
                  backgroundColor: `white`,
                  borderRadius: `10px`,
                  padding: `15px`,
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `space-between`,
                }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `space-between`,
                  }}
                >
                  <Box
                    sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}
                  >
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: `18px`,
                        fontWeight: 600,
                        color: `black`,
                      }}
                    >
                      {`Profit / Loss`}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: `14px`,
                        fontWeight: 600,
                        color: `gray`,
                      }}
                    >
                      {`(Monthly)`}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: `flex`,
                      flexDirection: `row`,
                      alignItems: `center`,
                      gap: `10px`,
                    }}
                  >
                    <Image
                      src={backArrow}
                      width={12}
                      height={12}
                      onClick={handleProfitLossBackward}
                      sx={{ cursor: `pointer` }}
                    />
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: `14px`,
                        fontWeight: 500,
                        lineHeight: `14.52px`,
                      }}
                    >{`${monthNames[expense?.profitLossMonth - 1]} ${
                      expense?.profitLossYear
                    }`}</Typography>

                    <Image
                      src={forwardArrow}
                      width={12}
                      height={12}
                      onClick={handleProfitLossForward}
                      sx={{ cursor: `pointer` }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `column`,
                    paddingX: `20px`,
                  }}
                >
                  <Box
                    sx={{
                      display: `flex`,
                      justifyContent: `space-between`,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: `flex`,
                        flexDirection: `column`,
                        gap: `15px`,
                        justifyContent: `flex-end`,
                        paddingY: `10px`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: `16px`,
                          fontWeight: 400,
                          color: `gray`,
                        }}
                      >
                        Income
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: `16px`,
                          fontWeight: 400,
                          color: `gray`,
                        }}
                      >
                        Expense
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: `16px`,
                          fontWeight: 400,
                          color: `gray`,
                        }}
                      >
                        Profit
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: `16px`,
                          fontWeight: 400,
                          color: `gray`,
                        }}
                      >
                        Loss
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: `flex`,
                        alignItems: `flex-end`,
                      }}
                    >
                      <Divider
                        sx={{
                          height: `72%`,
                          borderRightWidth: 1,
                        }}
                        orientation="vertical"
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: `flex`,
                        flexDirection: `column`,
                        alignItems: `center`,
                        paddingY: `10px`,
                      }}
                    >
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                          gap: `15px`,
                          justifyContent: `left`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `18px`,
                            fontWeight: 600,
                            color: `black`,
                          }}
                        >
                          PKR
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `black`,
                          }}
                        >
                          {isDecimal(profitLossData?.pkr?.incomePKR)
                            ? profitLossData?.pkr?.incomePKR.toFixed(2)
                            : profitLossData?.pkr?.incomePKR}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `black`,
                          }}
                        >
                          {isDecimal(profitLossData?.pkr?.expensePKR)
                            ? profitLossData?.pkr?.expensePKR.toFixed(2)
                            : profitLossData?.pkr?.expensePKR}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `rgba(25, 201, 209, 1)`,
                          }}
                        >
                          {isDecimal(profitLossData?.pkr?.profitPKR)
                            ? profitLossData?.pkr?.profitPKR.toFixed(2)
                            : profitLossData?.pkr?.profitPKR}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `rgba(255, 72, 94, 1)`,
                          }}
                        >
                          {isDecimal(profitLossData?.pkr?.lossPKR)
                            ? profitLossData?.pkr?.lossPKR.toFixed(2)
                            : profitLossData?.pkr?.lossPKR}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: `flex`, alignItems: `flex-end` }}>
                      <Divider
                        sx={{
                          height: `72%`,
                          borderRightWidth: 1,
                        }}
                        orientation="vertical"
                      />
                    </Box>
                    <Box
                      sx={{
                        flex: 1,
                        display: `flex`,
                        flexDirection: `column`,
                        alignItems: `center`,
                        paddingY: `10px`,
                      }}
                    >
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                          gap: `15px`,
                          justifyContent: `left`,
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `18px`,
                            fontWeight: 600,
                            color: `black`,
                          }}
                        >
                          USD
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `black`,
                          }}
                        >
                          {isDecimal(profitLossData?.usd?.incomeUSD)
                            ? profitLossData?.usd?.incomeUSD.toFixed(2)
                            : profitLossData?.usd?.incomeUSD}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `black`,
                          }}
                        >
                          {isDecimal(profitLossData?.usd?.expenseUSD)
                            ? profitLossData?.usd?.expenseUSD.toFixed(2)
                            : profitLossData?.usd?.expenseUSD}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `rgba(25, 201, 209, 1)`,
                          }}
                        >
                          {isDecimal(profitLossData?.usd?.profitUSD)
                            ? profitLossData?.usd?.profitUSD.toFixed(2)
                            : profitLossData?.usd?.profitUSD}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: `Inter`,
                            fontSize: `16px`,
                            fontWeight: 600,
                            color: `rgba(255, 72, 94, 1)`,
                          }}
                        >
                          {isDecimal(profitLossData?.usd?.lossUSD)
                            ? profitLossData?.usd?.lossUSD.toFixed(2)
                            : profitLossData?.usd?.lossUSD}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: `flex`,
                      gap: `5px`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      paddingTop: `10px`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: `Inter`,
                        fontSize: `18px`,
                        fontWeight: 600,
                      }}
                    >
                      Percentage
                    </Typography>
                    <Box
                      sx={{
                        display: `flex`,
                        flexDirection: `row`,
                        alignItems: `center`,
                        gap: `15px`,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: `16px`,
                          fontWeight: 500,
                          color: `gray`,
                        }}
                      >{`(Profit/Loss):`}</Typography>
                      <Typography
                        sx={{
                          fontFamily: `Inter`,
                          fontSize: `18px`,
                          fontWeight: 600,
                          color:
                            profitLossData?.profitLoss > 0
                              ? `rgba(25, 201, 209, 1)`
                              : profitLossData?.profitLoss == 0
                              ? `black`
                              : `rgba(255, 72, 94, 1)`,
                        }}
                      >
                        {profitLossData?.profitLoss > 0
                          ? isDecimal(profitLossData?.profitLoss)
                            ? `${profitLossData.profitLoss.toFixed(2)}% Profit `
                            : `${profitLossData?.profitLoss}% Profit`
                          : profitLossData?.profitLoss == 0
                          ? isDecimal(profitLossData?.profitLoss)
                            ? `${profitLossData.profitLoss.toFixed(2)}% `
                            : `${profitLossData?.profitLoss}%`
                          : isDecimal(Math.abs(profitLossData?.profitLoss))
                          ? `${Math.abs(
                              profitLossData.profitLoss.toFixed(2),
                            )}% Loss `
                          : `${Math.abs(profitLossData?.profitLoss)}% Loss`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              `No Data Found`
            )}
          </Grid>
        )}

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `space-between`,
                backgroundColor: `white`,
                borderRadius: `10px`,
                padding: `15px`,
                height: `300px`,
              }}
            >
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  fontWeight: 600,
                  fontSize: `18px`,
                }}
              >
                Profit / Loss
              </Typography>

              {lastSixMonthsProfitLostLoading ||
              lastSixMonthsProfitLostFetching ? (
                <GraphSkeleton />
              ) : lastSixMonthsProfitLostChart?.datasets?.length > 0 &&
                !lastSixMonthsProfitLostLoading &&
                !lastSixMonthsProfitLostFetching ? (
                <Bar
                  data={lastSixMonthsProfitLostChart}
                  options={{
                    scales: {
                      x: {
                        stacked: true,
                      },
                      y: {
                        stacked: true,
                      },
                    },
                    maintainAspectRatio: false,

                    layout: {
                      padding: {
                        top: 10,
                        bottom: 20,
                      },
                    },
                  }}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: `white`,
              borderRadius: `10px`,
              padding: `15px`,
              height: `300px`,
            }}
          >
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontWeight: 600,
                fontSize: `18px`,
                color: `black`,
              }}
            >
              {`Payroll Account's Budget`}
            </Typography>

            {payrollLoader ? (
              <GraphSkeleton />
            ) : payrollChart && !payrollLoader ? (
              <Bar data={payrollChart} options={options} />
            ) : (
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: `white`,
              borderRadius: `10px`,
              padding: `15px`,
              height: `300px`,
            }}
          >
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontWeight: 600,
                fontSize: `18px`,
              }}
            >
              Expenses
            </Typography>

            {monthlyExpenseLoader ? (
              <GraphSkeleton />
            ) : monthlyExpenseChart &&
              monthlyExpenseChart?.labels?.length &&
              !monthlyExpenseLoader ? (
              <Line data={monthlyExpenseChart} options={barChartOptions} />
            ) : (
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              backgroundColor: `white`,
              borderRadius: `10px`,
              padding: `15px`,
              height: `300px`,
            }}
          >
            <Box
              sx={{
                display: `flex`,
                justifyContent: `space-between`,
                alignItems: `center`,
                gap: `10px`,
              }}
            >
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  fontWeight: 600,
                  fontSize: `18px`,
                  paddingBottom: `10px`,
                }}
              >
                Expenses By Account
              </Typography>

              <FormControl
                size="small"
                sx={{
                  width: `250px`,
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: `rgba(40, 32, 61, 0.25)`,
                    },
                  },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: `rgba(40, 32, 61, 0.5)`,
                  }}
                >
                  Accounts *
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Accounts *"
                  value={expense.account || ``}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      account: event.target.value,
                    }))
                  }
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: `450px` } },
                  }}
                >
                  {accountsData ? (
                    accountsData?.map((data) => (
                      <MenuItem key={data?.value} value={data?.value}>
                        {data?.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Account Found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>

            {accountExpenseLoader || fetchAccountExpense ? (
              <GraphSkeleton />
            ) : accountExpenseChart &&
              accountExpenseChart?.labels?.length &&
              !accountExpenseLoader &&
              !fetchAccountExpense ? (
              <Line data={accountExpenseChart} options={barChartOptions} />
            ) : (
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              backgroundColor: `white`,
              borderRadius: `10px`,
              padding: `15px`,
              height: `300px`,
            }}
          >
            <Box
              sx={{
                display: `flex`,
                flexDirection: `row`,
                justifyContent: `space-between`,
                alignItems: `center`,
              }}
            >
              <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `18px`,
                    fontWeight: 600,
                    color: `black`,
                  }}
                >
                  {`Expenses of All Accounts`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `14px`,
                    fontWeight: 600,
                    color: `gray`,
                  }}
                >
                  {`(Monthly)`}
                </Typography>
              </Box>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl>
                  <DatePicker
                    label="Year-Month"
                    views={[`year`, `month`]}
                    inputFormat="YYYY-MM"
                    value={monthlyExpensesOfAllAccountsDate}
                    onChange={(date) => {
                      if (!date)
                        handleMonthlyExpensesOfAllAccountsDateChange(
                          moment(new Date()).format(`YYYY-MM`),
                        )
                      else
                        handleMonthlyExpensesOfAllAccountsDateChange(
                          moment(new Date(date)).format(`YYYY-MM`),
                        )
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        sx={{
                          width: `200px`,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              border: `solid  ${colors.appColor} 1px`,
                            },
                            '&.Mui-focused fieldset': {
                              border: `solid ${colors.appColor} 1px`,
                            },
                            '& fieldset': {
                              borderColor: `rgba(40, 32, 61, 0.5)`,
                              borderRadius: `10px`,
                            },
                            '& svg': { color: `#19C9D1` },
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: `rgba(40, 32, 61, 0.5)`,
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </LocalizationProvider>
            </Box>
            {monthlyExpensesOffAllAccountsLoader ||
            monthlyExpensesOffAllAccountsFetching ? (
              <GraphSkeleton />
            ) : monthlyExpensesOfAllAccountsChart?.datasets?.length > 0 &&
              !monthlyExpensesOffAllAccountsLoader &&
              !monthlyExpensesOffAllAccountsFetching ? (
              <Bar
                data={monthlyExpensesOfAllAccountsChart}
                options={barChartOptions}
              />
            ) : (
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </Grid>

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                backgroundColor: `white`,
                borderRadius: `10px`,
                padding: `15px`,
                height: `300px`,
              }}
            >
              <Box
                sx={{
                  display: `flex`,
                  alignItems: `center`,
                  justifyContent: `space-between`,
                  gap: `10px`,
                }}
              >
                <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `18px`,
                      fontWeight: 600,
                      color: `black`,
                    }}
                  >
                    {`Invoices`}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `14px`,
                      fontWeight: 600,
                      color: `gray`,
                    }}
                  >
                    {`(Monthly)`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `row`,
                    alignItems: `center`,
                    gap: `10px`,
                  }}
                >
                  <Image
                    src={backArrow}
                    width={12}
                    height={12}
                    onClick={handleInvoiceBackward}
                    sx={{ cursor: `pointer` }}
                  />
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `14px`,
                      fontWeight: 500,
                      lineHeight: `14.52px`,
                    }}
                  >{`${monthNames[expense?.invoiceMonth - 1]} ${
                    expense?.invoiceYear
                  }`}</Typography>

                  <Image
                    src={forwardArrow}
                    width={12}
                    height={12}
                    onClick={handleInvoiceForward}
                    sx={{ cursor: `pointer` }}
                  />
                </Box>
              </Box>

              {invoiceLoader || fetchInvoice ? (
                <GraphSkeleton />
              ) : invoiceChart?.labels?.length &&
                !invoiceLoader &&
                !fetchInvoice ? (
                <Bar data={invoiceChart} options={barChartOptions} />
              ) : (
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundColor: `white`,
              borderRadius: `10px`,
              padding: `15px`,
              height: `300px`,
            }}
          >
            <Typography
              sx={{
                fontFamily: `Inter`,
                fontSize: `18px`,
                fontWeight: 600,
                color: `black`,
              }}
            >
              Accounts Budgets
            </Typography>

            {accountsBudgetLoader ? (
              <GraphSkeleton />
            ) : accountBudgetChart && !accountsBudgetLoader ? (
              <Bar data={accountBudgetChart} options={options} />
            ) : (
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              backgroundColor: `white`,
              borderRadius: `10px`,
              padding: `15px`,
              height: `300px`,
            }}
          >
            <Box
              sx={{
                display: `flex`,
                justifyContent: `space-between`,
                alignItems: `center`,
                gap: `10px`,
              }}
            >
              <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `18px`,
                    fontWeight: 600,
                    color: `black`,
                  }}
                >
                  {`Transactions`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `14px`,
                    fontWeight: 600,
                    color: `gray`,
                  }}
                >
                  {`(By Funds)`}
                </Typography>
              </Box>
              <FormControl
                size="small"
                sx={{
                  width: `250px`,
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      border: `solid ${colors.appColor} 1px`,
                    },
                    '&.Mui-focused fieldset': {
                      border: `solid ${colors.appColor} 1px`,
                    },
                    '& fieldset': {
                      borderColor: `rgba(40, 32, 61, 0.25)`,
                      borderRadius: `10px`,
                    },
                  },
                  '& .MuiInputLabel-root': {
                    '&.Mui-focused': {
                      color: `rgba(40, 32, 61, 0.25)`,
                    },
                  },
                }}
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{
                    color: `rgba(40, 32, 61, 0.5)`,
                  }}
                >
                  Funds *
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Accounts *"
                  value={expense?.funds || ``}
                  disabled={systemRole === `HRM` || systemRole === `EMPLOYEE`}
                  onChange={(event) =>
                    setExpense((prev) => ({
                      ...prev,
                      funds: event.target.value,
                    }))
                  }
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: `450px` } },
                  }}
                >
                  {fundsData ? (
                    fundsData?.map((fund) => (
                      <MenuItem key={fund?._id} value={fund?._id}>
                        {`${fund?.allocatedBy?.name} - ${fund?.allocatedTo?.name}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No Fund found</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>

            {transactionsDataLoader || transactionsDataFetching ? (
              <GraphSkeleton />
            ) : transactChart &&
              !transactionsDataLoader &&
              !transactionsDataFetching ? (
              <Bar data={transactChart} options={options} />
            ) : (
              <Typography
                sx={{
                  fontFamily: `Inter`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                No Data Found
              </Typography>
            )}
          </Box>
        </Grid>

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: `white`,
                borderRadius: `10px`,
                padding: `15px`,
                height: `300px`,
              }}
            >
              <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `18px`,
                    fontWeight: 600,
                    color: `black`,
                  }}
                >
                  {`Businesses' Invoices`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `14px`,
                    fontWeight: 600,
                    color: `gray`,
                  }}
                >
                  {`(By Status)`}
                </Typography>
              </Box>

              {statusWiseAmountsOfAllVendorsLoading ||
              statusWiseAmountsOfAllVendorsFetching ? (
                <GraphSkeleton />
              ) : statusWiseAmountsOfAllVendorsChart?.datasets?.length > 0 &&
                !statusWiseAmountsOfAllVendorsLoading &&
                !statusWiseAmountsOfAllVendorsFetching ? (
                <Bar
                  data={statusWiseAmountsOfAllVendorsChart}
                  options={barChartOptions}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: `white`,
                borderRadius: `10px`,
                padding: `15px`,
                height: `300px`,
              }}
            >
              <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `18px`,
                    fontWeight: 600,
                    color: `black`,
                  }}
                >
                  {`Invoices Amounts`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    fontSize: `14px`,
                    fontWeight: 600,
                    color: `gray`,
                  }}
                >
                  {`(By Payment Mode)`}
                </Typography>
              </Box>

              {allInvoicesAmountOfAllPaymentModesLoading ||
              allInvoicesAmountOfAllPaymentModesFetching ? (
                <GraphSkeleton />
              ) : allInvoicesOfAllPaymentModesChart?.datasets?.length > 0 &&
                !allInvoicesAmountOfAllPaymentModesLoading &&
                !allInvoicesAmountOfAllPaymentModesFetching ? (
                <Bar
                  data={allInvoicesOfAllPaymentModesChart}
                  options={barChartOptions}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                backgroundColor: `white`,
                borderRadius: `10px`,
                padding: `15px`,
                height: `300px`,
              }}
            >
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `18px`,
                      fontWeight: 600,
                      color: `black`,
                    }}
                  >
                    {`Invoices Amounts`}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `14px`,
                      fontWeight: 600,
                      color: `gray`,
                    }}
                  >
                    {`(By Project)`}
                  </Typography>
                </Box>

                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: `250px`,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: colors.appColor,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: `rgba(40, 32, 61, 0.5)`,
                    }}
                  >
                    Projects
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="project"
                    label="Projects "
                    value={project || ``}
                    onChange={(event) =>
                      handleProjectsChange(event.target.value)
                    }
                  >
                    {listProjects?.length > 0 ? (
                      listProjects?.map((project) => (
                        <MenuItem key={project._id} value={project._id}>
                          {project.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>No Project Found</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>

              {allInvoicesAmountOfSingleProjectLoading ||
              allInvoicesAmountOfSingleProjectFetching ? (
                <GraphSkeleton />
              ) : allInvoicesAmountOfSingleProjectChart?.datasets?.length > 0 &&
                !allInvoicesAmountOfSingleProjectLoading &&
                !allInvoicesAmountOfSingleProjectFetching ? (
                <Bar
                  data={allInvoicesAmountOfSingleProjectChart}
                  options={barChartOptions}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </Box>
          </Grid>
        )}

        {systemRole === `CEO` && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                backgroundColor: `white`,
                borderRadius: `10px`,
                padding: `15px`,
                height: `300px`,
              }}
            >
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `row`,
                  justifyContent: `space-between`,
                  alignItems: `center`,
                }}
              >
                <Box sx={{ display: `flex`, gap: `5px`, alignItems: `center` }}>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `18px`,
                      fontWeight: 600,
                      color: `black`,
                    }}
                  >
                    {`Invoices Amounts`}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: `Inter`,
                      fontSize: `14px`,
                      fontWeight: 600,
                      color: `gray`,
                    }}
                  >
                    {`(By Client)`}
                  </Typography>
                </Box>

                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    width: `250px`,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        border: `solid ${colors.appColor} 1px`,
                      },
                      '&.Mui-focused fieldset': {
                        border: `solid ${colors.appColor} 1px`,
                      },
                      '& fieldset': {
                        borderColor: `rgba(40, 32, 61, 0.25)`,
                        borderRadius: `10px`,
                      },
                    },
                    '& .MuiInputLabel-root': {
                      '&.Mui-focused': {
                        color: colors.appColor,
                      },
                    },
                  }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: `rgba(40, 32, 61, 0.5)`,
                    }}
                  >
                    Clients
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="clients"
                    label="Clients "
                    value={client || ``}
                    onChange={(event) =>
                      handleClientsChange(event.target.value)
                    }
                  >
                    {listClients?.map((client, index) => (
                      <MenuItem key={index} value={client._id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {allInvoicesAmountOfSingleClientLoading ||
              allInvoicesAmountOfSingleClientFetching ? (
                <GraphSkeleton />
              ) : allInvoicesAmountOfSingleClientChart?.datasets?.length > 0 &&
                !allInvoicesAmountOfSingleClientLoading &&
                !allInvoicesAmountOfSingleClientFetching ? (
                <Bar
                  data={allInvoicesAmountOfSingleClientChart}
                  options={barChartOptions}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: `Inter`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  No Data Found
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default ExpenditureDashboard
